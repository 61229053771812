import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";

import s from "./StripePaymentForm.module.scss";
import { memo, useEffect, useState } from "react";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { IonToast } from "@ionic/react";
import { useFormsStore } from "../../../../Common/store/common";
import useWebSignupStore from "../store";
import { getErrorMsg } from "../../../../Common/utils/common";
import { bugNotif } from "../../../api/bugsnag";
import { WEB_DOWNLOAD_APP } from "../../../constants/routes";
import {
  sendTTQCheckoutEvent,
  startCheckoutKlaviyoEvent,
  trackWebSignUpStep,
} from "../../../services/user";

const ttqEventSent = false;
const StripePaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const setFormLoading = useFormsStore((state) => state.setFormLoading);
  const [error, setError] = useState("");

  const PAYMENT_ELEMENT_OPTIONS = {
    billingDetails: {
      address: "never",
      postalCode: "never",
    },

    terms: {
      card: "never",
      googlePay: "never",
      applePay: "never",
    },
  } as StripePaymentElementOptions;
  const {
    userId,
    selectedSubscription,
    stripeClientSecretId,
    firstName,
    lastName,
    phoneNumber,

    emailAddress,
    validStripeCoupon,

    setSubmitPayment,
  } = useWebSignupStore();

  const submitPaymentForm = async () => {
    if (selectedSubscription === undefined || isNull(selectedSubscription)) {
      return setError("No selected plan found. Please try again.");
    }

    if (stripeClientSecretId === undefined || isNull(stripeClientSecretId)) {
      return setError("Something went wrong. Please try again.");
    }

    if (!stripe) {
      return setError("Form is not yet ready. Please try again.");
    }

    if (isNull(elements)) {
      return setError("Something went wrong. Please try again.");
    }

    try {
      setFormLoading(true);
      const { error: submitError } = await elements.submit();
      if (submitError) {
        console.log({
          submitError,
        });
        setError(submitError.message || "");
        setFormLoading(false);
        return;
      }

      const tiktokSource = new URLSearchParams(window.location.search).get(
        "ttclid"
      );

      const promoCode = new URLSearchParams(window.location.search).get(
        "promo"
      );
      const promo =
        !isNull(promoCode) && !isEmpty(promoCode)
          ? promoCode.toUpperCase().trim()
          : null;
      const currentUrl = window.location.href; // Gets the current page's URL
      const parsedUrl = new URL(currentUrl);
      let domain = parsedUrl.hostname;
      const paramFullName = `${firstName} ${lastName}`.split(" ").join("_");
      let returnUrl = `https://${domain}${WEB_DOWNLOAD_APP}/?userId=${userId}&pid=${selectedSubscription.docId}&signedUpFullName=${paramFullName}&emailAddress=${emailAddress}&promoCode=${promo || "null"}&signUpVersion=6`;
      if (domain === "localhost") {
        returnUrl = `http://${domain}:5173${WEB_DOWNLOAD_APP}/?userId=${userId}&pid=${selectedSubscription.docId}&signedUpFullName=${paramFullName}&emailAddress=${emailAddress}&promoCode=${promo || "null"}&signUpVersion=6`;
      }

      if (!isNull(tiktokSource)) {
        returnUrl = `${returnUrl}&ttclid=${tiktokSource}`;
      }

      await sendTTQCheckoutEvent(
        emailAddress,
        phoneNumber,
        selectedSubscription,
        tiktokSource,
        validStripeCoupon
      );

      await startCheckoutKlaviyoEvent(
        selectedSubscription.docId,
        selectedSubscription.price
      );

      // await trackWebSignUpStep(emailAddress, "subscription", 6);
      await trackWebSignUpStep({
        emailAddress,
        step: "subscription",
        version: 6,
        subscription: selectedSubscription.name,
        promoCode: promo,
        revenue: null,
      });

      // Confirm the Intent using the details collected by the Payment Element
      const { error: errorPayment } = await stripe.confirmPayment({
        elements,
        clientSecret: stripeClientSecretId,
        confirmParams: {
          return_url: returnUrl,
        },
      });

      if (errorPayment) {
        setError(errorPayment.message || "");
      }

      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);

      const { errorMsg, rawErrorMsg } = getErrorMsg(error);
      setError(errorMsg);
      bugNotif("submitPaymentForm", rawErrorMsg);
    }
  };

  useEffect(() => {
    if (selectedSubscription === undefined || isNull(selectedSubscription)) {
      return;
    }

    if (stripeClientSecretId === undefined || isNull(stripeClientSecretId)) {
      return;
    }

    submitPaymentForm();
  }, [stripeClientSecretId, selectedSubscription]);

  return (
    <div className={s.paymentContainer}>
      <PaymentElement
        onReady={() => {
          setSubmitPayment(submitPaymentForm);
        }}
        options={PAYMENT_ELEMENT_OPTIONS}
      />

      <IonToast
        isOpen={!isEmpty(error)}
        color="danger"
        message={error}
        duration={5000}
      />
    </div>
  );
};

export default memo(StripePaymentForm);
