import {
  Body,
  Button,
  Column,
  Container,
  Head,
  Hr,
  Html,
  Img,
  Preview,
  Row,
  Section,
  Text,
} from "@react-email/components";

const logoUrl =
  "https://firebasestorage.googleapis.com/v0/b/winners-locker-development.appspot.com/o/AppIcon~ios-marketing.png?alt=media&token=35f315db-fee1-4c96-ad7f-58bad416a912";

interface CompleteProfileTemplateProps {
  firstName: string;
  link: string;
}

export const CompleteProfileTemplate = ({
  firstName,
  link,
}: CompleteProfileTemplateProps) => (
  <Html>
    <Head />
    <Preview>Welcome to Winners Locker!</Preview>
    <Body style={main}>
      <Container style={container}>
        <Section style={box}>
          <Row>
            <Column width="40" height="40">
              <Img
                src={logoUrl}
                width="40"
                height="40"
                style={{ marginRight: "8px" }}
              />
            </Column>
            <Column>
              <Text style={titleText}>Winners Locker</Text>
            </Column>
          </Row>
          <Hr style={hr} />
          <Text style={paragraph}>
            Hey {firstName},
            <br />
            <br />
            Welcome to Winners Locker! As a member, you'll score exclusive
            perks, access to epic giveaways every two weeks, and more ways to
            win and save.
            <br />
            <br />
            If you haven't already, make sure to complete your account so we
            know exactly how to contact you if you win. It only takes a minute:
            <br />
            <br />
            <Button style={button} href={link}>
              Complete Profile
            </Button>
            <br />
          </Text>
          <Hr style={hr} />
          <Text style={footer}>
            Already sorted? You're all good to go! 🙌 Make sure you download the
            app to track your entries, access perks, and stay in the loop!
            <br />
            <br />
            Need a hand? Our support team is here to help:
            https://help.winnerslocker.com.au/en-US
            <br />
            <br />
            Good luck, legend! 🏆
            <br />— The Winners Locker Team
          </Text>
        </Section>
      </Container>
    </Body>
  </Html>
);

export default CompleteProfileTemplate;

const main = {
  backgroundColor: "#f6f9fc",
  fontFamily:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif',
};

const container = {
  backgroundColor: "#ffffff",
  margin: "0 auto",
  padding: "20px 0 48px",
  marginBottom: "64px",
};

const box = {
  padding: "0 48px",
};

const hr = {
  borderColor: "#e6ebf1",
  margin: "20px 0",
};

const titleText = {
  fontSize: "16px",
  fontWeight: "700",
  color: "#101828",
  lineHeight: "38px",
};

const paragraph = {
  color: "#525f7f",

  fontSize: "16px",
  lineHeight: "24px",
  textAlign: "left" as const,
};

const button = {
  backgroundColor: "#de1025",
  borderRadius: "5px",
  color: "#fff",
  fontSize: "16px",
  fontWeight: "bold",
  textDecoration: "none",
  textAlign: "center" as const,
  display: "block",
  width: "100%",
  padding: "10px",
};

const footer = {
  color: "#8898aa",
  fontSize: "12px",
  lineHeight: "16px",
};
