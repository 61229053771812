import { AxiosResponse } from "axios";
import { Promo, PromoTypeConfig } from "../../Common/models/promos";
import { doPost, getRequestHeaders } from "../../Common/utils/https";
import { cloudFunctionsUrl } from "../firebase";

const baseUrl = cloudFunctionsUrl;

interface iPromoValidParams extends PromoTypeConfig {
  code: string;
}
export const validatePromoUnauthenticated = async (
  data: iPromoValidParams
): Promise<AxiosResponse<{ result: Promo | null }>> => {
  const url = `${baseUrl}promo/validateWLPromo`;
  const headers = await getRequestHeaders();
  return doPost(url, headers, { ...data });
};
