import {
  CreateAnalyticsWebSignUp,
  WebSignUpParamsAnalytics,
} from "../../Common/models/analytics";
import { doGet, doPost, encodeQueryData } from "../../Common/utils/https";
import { getRequestHeaders } from "../../Common/utils/https";
import { cloudFunctionsUrl } from "../firebase";
const baseUrl = cloudFunctionsUrl;

export const validateExistingUser = async (emailAddress: string) => {
  const url = `${baseUrl}users/validateExistingUser?${encodeQueryData({
    emailAddress,
  })}`;
  const headers = await getRequestHeaders();
  return doGet(url, headers);
};

export const getUserHashedDetails = async (userId: string) => {
  const url = `${baseUrl}users/getUserHashedDetails?${encodeQueryData({
    userId,
  })}`;
  const headers = await getRequestHeaders();
  return doGet(url, headers);
};

export const trackWebSignUpSteps = async (data: WebSignUpParamsAnalytics) => {
  const url = `${baseUrl}users/trackWebSignUpSteps`;
  const headers = await getRequestHeaders();
  return doPost(url, headers, data);
};

export const trackSignUpCheckoutMethod = async (data: {
  paymentIntentClientSecret: string;
  subscription: string;
  emailAddress: string;
  revenue: number;
  promoCode: string | null;
  express: boolean;
}) => {
  const url = `${baseUrl}users/trackSignUpCheckoutMethod`;
  const headers = await getRequestHeaders();
  return doPost(url, headers, data);
};

export const trackUserReferral = async (data: {
  referralMemberId: string;
  wlReferralConfigLookupKey: string;
  referredEmailAddress: string; // give this is required, you must only trigger this once email is set, but before user even clicks on submit. Will serve as a "View"
  revenue: number;
}) => {
  const url = `${baseUrl}users/trackUserReferral`;
  const headers = await getRequestHeaders();
  return doPost(url, headers, data);
};

export const sendEmail = async (data: {
  userId: string;
  subject: string;
  html: string;
}) => {
  const url = `${baseUrl}users/sendEmail`;
  const headers = await getRequestHeaders();
  return doPost(url, headers, data);
};

export const completeProfile = async (data: {
  userId: string;
  emailAddress: string;
  password: string;
  phoneNumber: string;
  residentialState: string;
  firstName: string;
  lastName: string;
}) => {
  const url = `${baseUrl}users/completeProfile`;
  const headers = await getRequestHeaders();
  return doPost(url, headers, data);
};
