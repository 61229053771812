import { db } from "../firebase";
import { getDocs, collection } from "../../Common/utils/firebaseFunctions";
import { WLConfig } from "../../Common/models/config";
import { WL_CONFIG } from "../../Common/constants/dbCollections";

export const getWlConfig = async () => {
  const wlConfigQuery = await getDocs(collection(db, WL_CONFIG));
  if (wlConfigQuery.empty) {
    return false;
  }

  const wlConfig = wlConfigQuery.docs[0].data() as WLConfig;
  return wlConfig;
};
