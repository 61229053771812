export const USER = "users";
export const LEVEL_REWARDS_MATRIX = "levelRewardsMatrix";
export const USER_ENTRIES_HISTORY = "userEntriesHistory";
export const USER_REWARDS_HISTORY = "userRewardPointsHistory";
export const USER_LOCKER_HISTORY = "userLockerRewardHistory";
export const SUBSCRIPTIONS = "subscriptions";
export const USER_POWER_UPS = "userPowerUps";
export const REVENUE_CAT_EVENTS_HISTORY = "revenueCatEventHistory";
export const STRIPE_EVENTS_HISTORY = "stripeEventHistory";
export const DISCOUNTS = "discounts";
export const GIVEAWAYS = "giveaways";
export const PROMOS = "promos";
export const USER_PROMO_HISTORY = "userPromoHistory";
export const CHALLENGES_AND_REWARDS = "challengesAndRewards";
export const CHALLENGES_AND_REWARDS_HISTORY = "challengesAndRewardsHistory";
export const REFERRAL_HISTORY = "referralHistory";

export const WINNERS_TESTAMENTS = "winnersTestaments";
export const PROMOTIONAL_BANNERS = "promotionalBanners";
export const MOBILE_VERSION_RELEASE = "mobileVersionRelease";
export const WL_CONFIG = "wlConfig";
export const WL_WHITELIST = "wlWhiteListUsers";
export const WL_KLAVIYO_UNASSIGNED_PROFILE_REFS =
  "wlKlaviyoUnassignedProfileRefs";

export const USER_SESSION = "userSessions";

// Analytics
export const ANALYTICS_BILLING_HISTORY = "analyticsBillingHistory";
export const ANALYTICS_UTM = "analyticsUTM";
export const USER_ERRORS = "userErrors";
export const ANALYTICS_WEB_USER_SIGN_UP = "analyticsWebUserSignUp";
export const ANALYTICS_WEB_USER_CANCEL_RESULTS = "analyticsUserCancelResult";
export const ANALYTICS_USER_OFFERED_UPGRADES = "analyticsUserOfferedUpgrades";
export const ANALYTICS_USER_OFFERED_FIRST_POWERUP =
  "analyticsUserOfferedPowerUps";
export const ANALYTICS_USER_CHALLENGES_AND_REWARDS =
  "analyticsUserChallengesAndRewards";

export const ANALYTICS_POWER_UP_V1 = "analyticsPowerUpV1";
export const ANALYTICS_POWER_UP_V2 = "analyticsPowerUpV2";

export const ANALYTCS_USER_REFERRAL = "analyticsUserReferrals";
