import { create } from "zustand";

interface WebPaymentLandingState {
  pid: string;
  setPid: (value: string) => void;

  redirectToApp: boolean;
  setRedirectToApp: (value: boolean) => void;

  signedUpName: string;
  setSignUpName: (value: string) => void;

  isExpressCheckout: boolean;
  setIsExpressCheckout: (value: boolean) => void;

  isOneOffEntry: boolean;
  setIsOneOffEntry: (value: boolean) => void;

  error: string;
  setError: (value: string) => void;

  paymentMessage: string;
  setPaymentMessage: (value: string) => void;

  paymentError: string;
  setPaymentError: (value: string) => void;
}

const useWebPaymentLandingStore = create<WebPaymentLandingState>((set) => ({
  pid: "",
  setPid: (pid) => set({ pid }),

  redirectToApp: false,
  setRedirectToApp: (redirectToApp) => set({ redirectToApp }),

  signedUpName: "",
  setSignUpName: (signedUpName) => set({ signedUpName }),

  isExpressCheckout: false,
  setIsExpressCheckout: (isExpressCheckout) => set({ isExpressCheckout }),

  isOneOffEntry: false,
  setIsOneOffEntry: (isOneOffEntry) => set({ isOneOffEntry }),

  error: "",
  setError: (error) => set({ error }),

  paymentMessage: "",
  setPaymentMessage: (paymentMessage) => set({ paymentMessage }),

  paymentError: "",
  setPaymentError: (paymentError) => set({ paymentError }),
}));

export default useWebPaymentLandingStore;
