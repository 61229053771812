import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router";
import { IonRouterOutlet } from "@ionic/react";

import "./Router.scss";
import * as routes from "../constants/routes";
import { ForgotPassword } from "../pages/ForgotPassword/ForgotPassword";
import { WebSignUp as SinglePageWebSignUp } from "../pages/SinglePageWebSignUp/WebSignUp";
import { WebSignUp as SinglePageWebSignUpOneOff } from "../pages/SinglePageWebSignUpOneOff/WebSignUp";
import { WebPaymentLanding } from "../pages/WebPaymentLanding/WebPaymentLanding";
import { ResetPassword } from "../pages/ResetPassword/ResetPassword";

export const RouterComponent = () => {
  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Route
          exact
          path={routes.WEB_SIGN_UP_V6}
          component={SinglePageWebSignUp}
        />

        <Route
          exact
          path={routes.WEB_SIGN_UP_V6_ONE_OFF}
          component={SinglePageWebSignUpOneOff}
        />

        <Route path={routes.WEB_DOWNLOAD_APP} component={WebPaymentLanding} />

        <Route exact path={routes.RESET_PASSWORD} component={ResetPassword} />
        <Route exact path={routes.FORGOT_PASSWORD} component={ForgotPassword} />
        <Redirect
          from="/"
          to={routes.WEB_SIGN_UP_V6.replace(":subscriptionKey", "mvp")}
          exact
        />

        <Route
          component={() => (
            <Redirect
              to={routes.WEB_SIGN_UP_V6.replace(":subscriptionKey", "mvp")}
            />
          )}
        />
      </IonRouterOutlet>
    </IonReactRouter>
  );
};
