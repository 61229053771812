export const EN_TEXT_ERRORS = {
  // Auth Errors
  userNotFound: "User not found.",
  wrongPassword: "Wrong email or password, Please try again.",
  networkRequestFailed: "Something went wrong. Please try again.",
  emailAlreadyInUse: "An account with this email address already exists.",
  requiresRecentLogin: "This operation requires logging back in.",
  tooManyRequests:
    "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
  invalidEmail: "Incorrect email address format.",

  // Connection Errors
  connectionLost: "Connection lost. Please try again.",
  somethingWentWrong: "Something went wrong. Please try again.",
  requestFailed400: "Please try again",
  somethingWentWrongConnection:
    "Something went wrong. Please check your internet connection and try again.",
  oopsTroubleLoading:
    "Oops! We're having trouble loading. Check your internet and give it another go",

  // Form Field Errors
  requiredFieldError: "This is required.",
  firstNameFieldRequiredError: "Please enter your first name",
  lastNameFieldRequiredError: "Please enter your last name",
  emailAddressFieldRequiredError: "Your email address is required",
  phoneNumberFieldRequiredError: "Your mobile number is required",
  passwordFieldRequiredError: "Your password is required",
  newPasswordFieldRquiredError: "Please enter New Password",
  passwordDoesntMatchError: "Password doesn't match",
  businessNameFieldRequiredError: "Please enter Business Name",
  descriptionFieldRequiredError: "Please enter Description",
  urlFieldRequiredError: "Please enter URL",
  urlInvalidError: "Please enter a valid error",

  titleFieldRequiredError: "Please enter Title",
  codeFieldRequiredError: "Please enter Code",
  promoCodeFieldRequiredError: "Please enter Promo Code",
  powerUpTitleFieldRequiredError: "Please enter Power Up title",
  valueInAUDFieldRequiredError: "Enter Value in AUD",
  entryPointsFieldRequiredError: "Please enter Entry Points",
  lockerPointsFieldRequiredError: "Please enter Locker Points",
  bonusMonthsFieldRequiredError: "Please enter Bonus Months",
  residentialStateFieldRequiredError: "Your residential state is required",
  respinCostFieldRequiredError: "Please enter respin cost",
  cancelFlowDiscountFieldRequiredError: "Please enter Cancel Flow Discount",
  notesFieldRequiredError: "Please enter notes",
  winnerNameFieldRequiredError: "Please enter Winner Name",
  testamentFieldRequiredError: "Please enter Testament",
  dateOfBirthFieldRequiredError: "Your date of birth is required",
  nameFieldRequiredError: "Your name is required",
  cityFieldRequiredError: "Your city is required",
  postCodeFieldRequiredError: "Your post code is required",
  addressFieldRequiredError: "Your address is required",
  dateFromMustBeBeforeDateToError: `"Date from" must be before "Date to`,
  dateMustBeInThePastError: "Date must be in the past or today",
  dateMustBeInTheFutureError: "Date must be in the future.",
  giveawayDateMustBeAfterOpenDateError:
    "Giveaway date must be after the Open date.",
  openDateMustBeBeforeGiveawayDateError:
    "Open date must be before the Giveaway date.",
  announcementDateMustBeAfterOpenDateError:
    "Announcement date must be After the Open date.",
  announcementDateMustBeBeforeGiveawayDateError:
    "Announcement date must be before the Giveaway date.",
  entryPointsMustBeMoreThanOrEqualZeroError:
    "Entry Points must be more than or equals 0",
  lockerPointsMustBeMoreThanOrEqualZeroError:
    "Locker Points must be more than or equals 0",
  stripeCreditMustBeMoreOrEqualZeroError:
    "Stripe Credit must be more than or equals 0",
  pleaseSelectOneError: "Please select one.",
  bonusMonthsMustBeMoreOrEqualZeroError:
    "Bonus Months must be more than or equals 0",
  respinCostMoreOrEqualZeroError: "Respin Cost must be more than or equals 0",
  cancelFlowDiscountMoreOrEqualZeroError:
    "Cancel Flow Discount must be more than or equals 0",
  validPromoCodeError: "Please enter a valid promo code",
  invalidPromoCodeError: "Invalid promo code",
  mustBe18: "Must be 18 years old to join the team",
  mustBeMoreOrEqualZeroError: "Must be more than or equals 0",
  amountMustBeMoreOrEqualZeroError: "Amount must be more than 0",
  chanceMustBeMoreOrEqualZeroError: "Chance must be more than 0",
  totalChanceMustBe100: "Total chance must reach 100%",

  mustHaveAtLeast1Default: "Must have at least 1 default.",
  lookupKeysMustBeUniq: "Lookup keys must be unique",

  webUrlorRedirectRequired: "Web URL or Redirect URL is required",
};
