import cx from "classnames";

import s from "./ProgressBar.module.scss";
import { IonLabel } from "@ionic/react";

export const ProgressBar = () => {
  return (
    <div className={s.meter}>
      <span style={{ width: "80%" }}></span>

      <IonLabel className={cx(s.text, "wl-body-4 gilroy white")}>
        Account 80% complete
      </IonLabel>
    </div>
  );
};
