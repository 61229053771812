import isEmpty from "lodash/isEmpty";
import { useRef, useState } from "react";
import { render } from "@react-email/components";
import { renderToStaticMarkup } from "react-dom/server";

import { useForm } from "react-hook-form";
import { WLFormInputProps } from "../../../../Common/components/WLForms/WLFormInput/WLFormInput";
import { WLFormMobileNumberInputProps } from "../../../../Common/components/WLForms/WLFormMobileNumberInput/WLFormMobileNumberInput";
import {
  getErrorMsg,
  isMobile,
  useEffectOnlyOnce,
  validateNumber,
} from "../../../../Common/utils/common";
import { WLSelectInputProps } from "../../../../Common/components/WLForms/WLSelectInput/WLSelectInput";
import { ENVIRONMENTS, RESIDENTIAL_STATES } from "../../../../Common/config";
import { useFormsStore } from "../../../../Common/store/common";
import { bugNotif } from "../../../api/bugsnag";
import { completeProfile } from "../../../api/user";
import countriesJson from "../../../../Common/constants/countries.json";
import useCompleteProfileModalStore from "./store";
import { sendEmail } from "../../../api/user";
import CompleteProfileTemplate from "../../../../Common/components/email/CompleteProfileTemplate";
import { EN_TEXT_ERRORS } from "../../../../Common/constants/error";

interface CompleteProfile {
  emailAddress: string;
  password: string;
  phoneNumber: string;
  suburb: string;
  firstName: string;
  lastName: string;
}

const useCompleteProfileModalProvider = (params: {
  emailAddress: string;
  phoneNumber: string;
  fullName: string;
  userId: string;
}) => {
  const useFormMethods = useForm<CompleteProfile>({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const { handleSubmit } = useFormMethods;
  const { setValue, watch } = useFormMethods;
  const [error, setError] = useState("");
  const setFormLoading = useFormsStore((state) => state.setFormLoading);

  const { setIsOpen, setIsProfileComplete } = useCompleteProfileModalStore();

  const emailField: WLFormInputProps = {
    label: "Email Address",
    name: "emailAddress",
    type: "email",
    placeholder: "Enter email address",
    disabled: true,

    rules: {
      required: EN_TEXT_ERRORS.emailAddressFieldRequiredError,
      pattern: {
        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
        message: "Incorrect email address format",
      },
    },
  };

  const passwordField: WLFormInputProps = {
    label: "Password",
    name: "password",
    role: "password",
    type: "password",
    placeholder: "Enter password",
    rules: {
      required: EN_TEXT_ERRORS.passwordFieldRequiredError,
      minLength: {
        value: 6,
        message: "Your passsword must be at least 6 characters",
      },
    },
  };

  const phoneNumberField: WLFormMobileNumberInputProps = {
    placeholder: "Enter mobile number",
    label: "Mobile Number",
    name: "phoneNumber",
    role: "phoneNumber",

    rules: {
      required: EN_TEXT_ERRORS.phoneNumberFieldRequiredError,
      validate: (value) => {
        return validateNumber(value);
      },
    },
  };

  const stateFieldRef = useRef("");
  const stateField: WLSelectInputProps = {
    label: "Residential State",
    selectOptions: RESIDENTIAL_STATES,
    name: "suburb",
    placeholder: "Select residential state",
    rules: {
      required: EN_TEXT_ERRORS.residentialStateFieldRequiredError,
    },
  };
  stateFieldRef.current = watch("suburb", "");

  const firstNameField: WLFormInputProps = {
    label: "First Name",
    name: "firstName",
    role: "firstName",
    type: "text",
    placeholder: "Enter your first name",

    rules: {
      required: EN_TEXT_ERRORS.firstNameFieldRequiredError,
      maxLength: {
        value: 100,
        message: "Your first name is too long",
      },
    },
  };

  const lastNameField: WLFormInputProps = {
    label: "Last Name",
    name: "lastName",
    role: "lastName",
    type: "text",
    placeholder: "Enter your last name",

    rules: {
      required: EN_TEXT_ERRORS.lastNameFieldRequiredError,
      maxLength: {
        value: 100,
        message: "Your last name is too long",
      },
    },
  };

  useEffectOnlyOnce(() => {
    const { emailAddress, phoneNumber, fullName } = params;
    const nameSplit = fullName.split(" ");
    setValue("emailAddress", emailAddress);
    if (!isEmpty(phoneNumber)) {
      const defaultCountryCode =
        import.meta.env.MODE === ENVIRONMENTS.production
          ? countriesJson.AU.callingCode
          : countriesJson.PH.callingCode;
      const defaultCountryCodeWOPlus = defaultCountryCode.replace("+", "");
      let phoneNumberToUse = phoneNumber.trim();

      if (phoneNumberToUse[0] === "0") {
        phoneNumberToUse = phoneNumberToUse.replace("0", defaultCountryCode);
      } else if (phoneNumberToUse.indexOf(defaultCountryCodeWOPlus) === 0) {
        phoneNumberToUse = `+${phoneNumberToUse}`;
      } else if (phoneNumberToUse.indexOf(defaultCountryCode) === -1) {
        phoneNumberToUse = `${defaultCountryCode}${phoneNumberToUse}`;
      }

      setValue("phoneNumber", phoneNumberToUse);
    }

    const firstName = nameSplit.length > 0 ? nameSplit[0] : "";
    setValue("firstName", firstName);
    setValue(
      "lastName",
      nameSplit.length > 0 ? nameSplit[nameSplit.length - 1] : ""
    );

    sendEmailBackup(
      firstName || "dear member",
      window.location.href,
      params.userId
    );
    setIsOpen(true);
  });

  const sendEmailBackup = async (
    name: string,
    returnUrl: string,
    userId: string
  ) => {
    try {
      const renderedHtml = await (isMobile() ? renderToStaticMarkup : render)(
        <CompleteProfileTemplate
          firstName={name || "dear member"}
          link={returnUrl}
        />
      );

      await sendEmail({
        userId,
        subject: "You're in! Complete your details so we can contact you",
        html: renderedHtml as string,
      });
    } catch (error) {
      bugNotif("sendEmailBackup", getErrorMsg(error).rawErrorMsg);
    }
  };

  //   Hey [First Name],

  // Welcome to Winners Locker! As a member, you'll score exclusive perks, access to epic giveaways every two weeks, and more ways to win and save.

  // If you haven't already, make sure to complete your account so we know exactly how to contact you if you win. It only takes a minute: [Link Here]

  // Already sorted? You're all good to go! 🙌 Make sure you download the app to track your entries, access perks, and stay in the loop!

  // Need a hand? Our support team is here to help: [https://help.winnerslocker.com.au/en-US]

  // Good luck, legend! 🏆

  // — The Winners Locker Team

  const onSubmit = async (data: CompleteProfile) => {
    try {
      setFormLoading(true);
      const {
        emailAddress,
        password,
        phoneNumber,
        suburb,
        firstName,
        lastName,
      } = data;

      await completeProfile({
        userId: params.userId,
        emailAddress,
        password,
        phoneNumber,
        firstName,
        lastName,
        residentialState: suburb,
      });

      setFormLoading(false);

      setIsOpen(false);
      setIsProfileComplete(true);
    } catch (error) {
      setFormLoading(false);
      const { errorMsg, rawErrorMsg } = getErrorMsg(error);
      setError(errorMsg);

      bugNotif("submit complete profile", rawErrorMsg);
    }
  };

  return {
    useFormMethods,
    submit: handleSubmit(onSubmit),
    error,
    emailField,
    passwordField,
    phoneNumberField,
    stateField,
    stateFieldValue: stateFieldRef.current,
    firstNameField,
    lastNameField,
  };
};

export default useCompleteProfileModalProvider;
