import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import qs from "qs";
import React, { lazy, Suspense, useRef, useState } from "react";
import s from "./ResetPassword.module.scss";
import blueSmiley from "../../assets/icons/blue-smiley.svg";
import brandLogo from "../../assets/images/brand-logo.svg";

import {
  IonContent,
  IonImg,
  IonLabel,
  IonModal,
  IonPage,
  IonToast,
  useIonViewDidLeave,
} from "@ionic/react";
import { useHistory } from "react-router";
import {
  WLFormInput,
  WLFormInputProps,
} from "../../../Common/components/WLForms/WLFormInput/WLFormInput";
import { FormProvider, useForm } from "react-hook-form";
import { WLButton } from "../../../Common/components/WLButton/WLButton";
import { confirmResetPassword } from "../../services/user";
import { getErrorMsg, isMobile } from "../../../Common/utils/common";
import { bugNotif } from "../../api/bugsnag";
import { EN_TEXT_ERRORS } from "../../../Common/constants/error";

const SuccessModal = lazy(() => import("./SuccessModal/SuccessModal"));
const SignUpFooter = lazy(
  () => import("../../../Common/components/SignUpFooter/SignUpFooter")
);

interface ResetPassword {
  password: string;
  confirmPassword: string;
}

interface ResetPasswordURLQuery {
  oobCode: string;
}
export const ResetPassword = () => {
  const history = useHistory();

  const useFormMethods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { handleSubmit, watch, setValue, clearErrors } = useFormMethods;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const passwordField: WLFormInputProps = {
    label: "Password",
    name: "password",
    type: "password",
    placeholder: "Enter your password",
    rules: {
      required: EN_TEXT_ERRORS.passwordFieldRequiredError,
      minLength: {
        value: 8,
        message: "Must be at least 8 characters.",
      },
    },
  };

  const passwordRef = useRef({});
  passwordRef.current = watch(passwordField.name, "");

  const confirmPasswordField: WLFormInputProps = {
    label: "Confirm Password",
    name: "confirmPassword",
    type: "password",
    placeholder: "Confirm password",
    helperText: "Both passwords must match.",
    rules: {
      required: EN_TEXT_ERRORS.passwordFieldRequiredError,
      validate: (value: string) => {
        if (value !== passwordRef.current)
          return EN_TEXT_ERRORS.passwordDoesntMatchError;
      },
    },
  };

  const resetPassword = async (data: any) => {
    try {
      const resetData = data as ResetPassword;
      const urlQuery = history.location;
      const obj = qs.parse(urlQuery.search) as unknown as ResetPasswordURLQuery;
      await confirmResetPassword(obj.oobCode, resetData.password);
      setSuccess("Password reset successful!");
    } catch (error) {
      const { errorMsg, rawErrorMsg } = getErrorMsg(error);
      setError(errorMsg);
      bugNotif("reset password", rawErrorMsg);
    }
  };

  const cleanup = () => {
    setValue(passwordField.name, "");
    setValue(confirmPasswordField.name, "");
    clearErrors();
  };

  useIonViewDidLeave(() => {
    cleanup();
  });

  return (
    <IonPage className={s.page}>
      <IonContent className={s.content} fullscreen>
        {!isMobile() && <IonImg src={brandLogo} className={s.brandLogo} />}

        <div className={s.main}>
          <div className={s.container}>
            {!isMobile() && (
              <IonImg src={blueSmiley} className={s.smileyIcon} />
            )}
            <IonLabel className="wl-h7">RESET PASSWORD</IonLabel>
            <FormProvider {...useFormMethods}>
              <form className={s.form} onSubmit={handleSubmit(resetPassword)}>
                <WLFormInput {...passwordField} />
                <WLFormInput {...confirmPasswordField} />
                <WLButton
                  type="submit"
                  className={cx(s.button, "wl-body-6 red-submit-button")}
                >
                  Done
                </WLButton>
              </form>
            </FormProvider>
          </div>
        </div>
        <div className={s.footer}>
          <Suspense>
            <SignUpFooter />
          </Suspense>
        </div>
      </IonContent>
      <IonToast
        isOpen={!isEmpty(error)}
        message={error}
        color="danger"
        duration={3000}
        onDidDismiss={() => {
          setError("");
        }}
      />

      <IonModal isOpen={!isEmpty(success)} className={s.redirectToAppModal}>
        <Suspense>
          <SuccessModal
            onCloseAsModal={() => {
              setSuccess("");
            }}
          />
        </Suspense>
      </IonModal>
    </IonPage>
  );
};
