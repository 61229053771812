import cx from "classnames";
import { IonImg, IonButton, IonLabel, IonCol } from "@ionic/react";

import s from "./WebPaymentLanding.module.scss";
import qrCode from "../../assets/images/app-qr-code.png";
import appStoreLogo from "../../assets/images/app-store-badge.png";
import playStoreLogo from "../../assets/images/google-play-badge.png";

export const WLDownloadButtons = () => {
  return (
    <div className={s.downloadOptions}>
      <div className={s.scanCodeContainer}>
        <IonImg className={s.scanQrCode} src={qrCode} />
        <IonLabel className={cx("wl-body-6", s.scanLabel)}>
          Scan to download the app
        </IonLabel>
      </div>

      <div className={s.downloadButtonsContainer}>
        <IonButton
          fill="clear"
          className="ion-no-padding"
          onClick={() => {
            window.open(
              "https://apps.apple.com/us/app/winners-locker/id6454697542"
            );
          }}
        >
          <IonImg className={s.downloadButtonImage} src={appStoreLogo} />
        </IonButton>
        <IonButton
          fill="clear"
          className="ion-no-padding"
          onClick={() => {
            window.open(
              "https://play.google.com/store/apps/details?id=com.winnerslocker.winnerslocker"
            );
          }}
        >
          <IonImg className={s.downloadButtonImage} src={playStoreLogo} />
        </IonButton>
      </div>
    </div>
  );
};
