import cx from "classnames";
import { IonContent, IonLabel, IonModal, IonToast } from "@ionic/react";
import s from "./CompleteProfileModal.module.scss";
import { memo, useEffect, useState } from "react";
import useCompleteProfileModalProvider from "./provider";
import isEmpty from "lodash/isEmpty";
import { WLCOLORS } from "../../../../Common/config/global";
import useCompleteProfileModalStore from "./store";
import { FormProvider } from "react-hook-form";
import { WLFormInput } from "../../../../Common/components/WLForms/WLFormInput/WLFormInput";
import { WLFormMobileNumberInput } from "../../../../Common/components/WLForms/WLFormMobileNumberInput/WLFormMobileNumberInput";
import { WLSelectInput } from "../../../../Common/components/WLForms/WLSelectInput/WLSelectInput";
import { WLButton } from "../../../../Common/components/WLButton/WLButton";

const CompleteProfileModal = (props: {
  emailAddress: string;
  phoneNumber: string;
  fullName: string;
  userId: string;
}) => {
  const {
    useFormMethods,
    submit,
    error,

    emailField,
    passwordField,
    phoneNumberField,
    stateField,
    stateFieldValue,
    firstNameField,
    lastNameField,
  } = useCompleteProfileModalProvider(props);

  const { isOpen } = useCompleteProfileModalStore();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust breakpoint

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <IonModal className={s.modal} isOpen={isOpen} backdropDismiss={false}>
      <IonContent className={s.content}>
        <div className={s.body}>
          <FormProvider {...useFormMethods}>
            <form onSubmit={submit} className={s.form}>
              <div className={s.header}>
                <IonLabel
                  className={cx(s.headerTitle, "wl-h2-winnersans primary")}
                >
                  PAYMENT COMPLETE!
                </IonLabel>
                <IonLabel
                  className={cx(
                    s.headerDesc,
                    "wl-body-2-gilroy gilroy-regular light-dark"
                  )}
                >
                  Don't forget to fill in your details below so we know how to
                  contact you if you win.
                </IonLabel>
              </div>
              <div className={s.nameRow}>
                <WLFormInput {...firstNameField} />
                <WLFormInput {...lastNameField} />
              </div>
              <WLFormMobileNumberInput {...phoneNumberField} />
              <WLFormInput {...emailField} />
              <WLFormInput {...passwordField} />
              <WLSelectInput
                {...stateField}
                interface={isMobile ? "action-sheet" : "popover"}
              />
              {["SA", "ACT"].indexOf(stateFieldValue) !== -1 && (
                <IonLabel className={cx(s.stateNote, "wl-body-6")}>
                  Note: Due to state regulations, if you are a resident of{" "}
                  {stateFieldValue} you cannot win our major giveaways
                </IonLabel>
              )}
              <WLButton
                type="submit"
                className={cx(
                  s.submitBtn,
                  "wl-header-gilroy red-submit-button"
                )}
                id="wl-sign-up-v3"
                isFormComponent
              >
                COMPLETE PROFILE
              </WLButton>
            </form>
          </FormProvider>
        </div>
      </IonContent>
      <IonToast
        message={error}
        isOpen={!isEmpty(error)}
        duration={2000}
        color={WLCOLORS.danger}
      />
    </IonModal>
  );
};

export default memo(CompleteProfileModal);
