import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";

import { useIonRouter } from "@ionic/react";
import { loadStripe, PaymentIntent } from "@stripe/stripe-js";
import { useState } from "react";
import { stripeKey } from "../../firebase";
import {
  HARDCODED_SUBS_PRICING,
  STRIPE_SUBSCRIPTION_NAME_MAP,
} from "../../../Common/config";
import {
  completePurchaseSubscriptionKlaviyoEvent,
  sendFBTracking,
  sendSnapTracking,
  sendTTQCompletedPurchaseEvent,
  sendgtag,
  sendttqTracking,
  trackWebSignUpStep,
} from "../../services/user";
import { getErrorMsg, useEffectOnlyOnce } from "../../../Common/utils/common";
import { bugNotif } from "../../api/bugsnag";
import { WEB_SIGN_UP_OLD } from "../../constants/routes";
import useWebPaymentLandingStore from "./store";
import { trackSignUpCheckoutMethod } from "../../api/user";

export const useWebPaymentLandingProvider = (redirectToApp?: boolean) => {
  const router = useIonRouter();

  const {
    isExpressCheckout,
    setError,
    setPaymentMessage,
    setPaymentError,
    setSignUpName,
    setPid,
    setRedirectToApp,
    setIsExpressCheckout,
    setIsOneOffEntry,
  } = useWebPaymentLandingStore();

  // const [existingUser, setExistingUser] = useState(false);
  // const [powerUp, setPowerUp] = useState(false);
  // const [error, setError] = useState("");
  // const [paymentMessage, setPaymentMessage] = useState("");
  // const [paymentError, setPaymentError] = useState("");

  useEffectOnlyOnce(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    const userId = new URLSearchParams(window.location.search).get("userId");

    const pid = new URLSearchParams(window.location.search).get("pid");
    const signedUpFullName = new URLSearchParams(window.location.search).get(
      "signedUpFullName"
    );

    const expressCheckout = new URLSearchParams(window.location.search).get(
      "expressCheckout"
    );

    if (expressCheckout === "true") {
      setIsExpressCheckout(true);
    }

    const oneOffEntry = new URLSearchParams(window.location.search).get(
      "oneOffEntry"
    );

    if (oneOffEntry === "true") {
      setIsOneOffEntry(true);
    }

    console.log({ clientSecret });

    if (signedUpFullName) {
      setSignUpName(signedUpFullName.split("_").join(" "));
    }

    setPid(pid || "");

    if (
      !isEmpty(clientSecret) &&
      !isEmpty(userId) &&
      !isNull(clientSecret) &&
      !isNull(userId)
    ) {
      checkPaymentStatus(clientSecret, userId, pid || "");
    } else if (!redirectToApp) {
      router.push(WEB_SIGN_UP_OLD, "back", "pop");
    } else {
      setRedirectToApp(redirectToApp);
      if (redirectToApp) {
        setPaymentMessage("YOU ALREADY HAVE AN ACCOUNT WITH US");
      }
    }
  });

  const saveTrackSignUpCheckoutMethod = async (
    paymentIntent: PaymentIntent,
    pid: string,
    amount: number,
    promo: string | null,
    emailAddress: string | null
  ) => {
    try {
      await trackSignUpCheckoutMethod({
        paymentIntentClientSecret: paymentIntent.id,
        subscription: STRIPE_SUBSCRIPTION_NAME_MAP[pid],
        emailAddress: emailAddress || "",
        revenue: amount,
        promoCode: promo,
        express: useWebPaymentLandingStore.getState().isExpressCheckout,
      });
    } catch (error) {
      bugNotif("trackSignUpCheckoutMethod", getErrorMsg(error).rawErrorMsg);
    }
  };

  let tries = 0;
  const checkPaymentStatus = async (
    intentSecret: string,
    userId: string,
    pid = ""
  ) => {
    const stripe = await loadStripe(stripeKey);
    const isOneOffEntry = useWebPaymentLandingStore.getState().isOneOffEntry;
    if (!isNull(stripe)) {
      stripe
        .retrievePaymentIntent(intentSecret)
        .then(async ({ paymentIntent }) => {
          console.log("got payment intent --", paymentIntent);
          if (paymentIntent !== undefined) {
            switch (paymentIntent.status) {
              case "succeeded":
                if (isOneOffEntry) {
                  setPaymentMessage("YOU'RE IN!");
                } else {
                  setPaymentMessage("MEMBERSHIP UNLOCKED!");
                }
                break;

              default:
                setPaymentError("Something went wrong.");
                break; // if something went wrong, delete stripe customer and then show QR
            }

            if (paymentIntent.status === "succeeded" && !isEmpty(pid)) {
              const amount = paymentIntent.amount / 100;
              sendgtag(paymentIntent.id, amount);
              const event_id = await sendttqTracking(userId, pid, amount);
              sendSnapTracking(userId, "Membership", pid, amount);
              sendFBTracking("Membership", pid, amount);
              const tiktokSource = new URLSearchParams(
                window.location.search
              ).get("ttclid");
              sendTTQCompletedPurchaseEvent(
                userId,
                amount,
                pid,
                tiktokSource,
                event_id
              );

              const emailAddress = new URLSearchParams(
                window.location.search
              ).get("emailAddress");

              const signUpVersion = new URLSearchParams(
                window.location.search
              ).get("signUpVersion");

              const promoCode = new URLSearchParams(window.location.search).get(
                "promoCode"
              );
              const promo =
                !isNull(promoCode) &&
                !isEmpty(promoCode) &&
                promoCode !== "null"
                  ? promoCode.toUpperCase().trim()
                  : null;

              await trackWebSignUpStep({
                emailAddress: emailAddress || "",
                step: "completeSignUp",
                version: parseInt(signUpVersion || "6") as 6 | 7 | 8,
                subscription: STRIPE_SUBSCRIPTION_NAME_MAP[pid],
                revenue: amount,
                promoCode: promo,
              });

              completePurchaseSubscriptionKlaviyoEvent(pid, amount);

              await saveTrackSignUpCheckoutMethod(
                paymentIntent,
                pid,
                amount,
                promo,
                emailAddress
              );
            }
          } else {
            setPaymentError("Something went wrong. Please try again.");
          }
        })
        .catch((error) => {
          setPaymentMessage(
            isOneOffEntry ? "YOU'RE IN!" : "MEMBERSHIP UNLOCKED!"
          );

          bugNotif(
            "stripe.retrievePaymentIntent",
            getErrorMsg(error).rawErrorMsg
          );
        });
    } else {
      setTimeout(async () => {
        tries += 1;
        if (tries < 3) {
          checkPaymentStatus(intentSecret, userId, pid);
        } else {
          setError("Something went wrong. Please contact us for support.");
        }
      }, 500);
    }
  };

  return;
};
