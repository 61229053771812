import cx from "classnames";
import React from "react";
import get from "lodash/get";
import { IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";

import "./WLSelectInput.scss";

// Get all props from IonInput then removed className and onIonChange to override
type OverriddentIonInputProps = Omit<
  React.ComponentProps<typeof IonSelect>,
  "className" | "onIonChange" | "value"
>;

interface iSelectOptions {
  value: any;
  label: string;
}
// see https://ionicframework.com/docs/api/input for the IonInput props
export interface WLSelectInputProps extends Partial<OverriddentIonInputProps> {
  name: string;
  selectOptions: iSelectOptions[];
  className?: string;
  rules?: RegisterOptions;
  label?: string;
  noMargin?: boolean;
  presentation?: "date" | "month-year";
  onCustomChange?: (value: any) => void;
}

/**
 * !Before using this component:
 * - Make sure parent is wrapped in <FormProvider></FormProvider>
 * - Make sure useForm methods is passed in FormProvider. Example:
 *  const useFormMethods = useForm({
 *   mode: "all",
 *   reValidateMode: "onChange",
 * });
 * <FormProvider {...useFormMethods}>
 *  <WLFormInput/>
 */
export const WLSelectInput = (props: WLSelectInputProps) => {
  const {
    name,
    selectOptions,
    className,
    rules,
    label,
    placeholder,
    presentation,
    noMargin,
    interface: selectInterface,
    onCustomChange,
    ...remainingProps
  } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMsg = get(errors, `${name}.message`, undefined) as
    | string
    | undefined;
  const hasErrors = !!errors && errorMsg;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => {
        return (
          <div className="wl-select-cta">
            {label && (
              <IonLabel
                className={cx(
                  "input-label wl-body-6 blue500 ion-text-start",
                  rules !== undefined &&
                    rules.required !== undefined &&
                    "required"
                )}
              >
                {label}
              </IonLabel>
            )}

            <IonSelect
              mode="md"
              labelPlacement="stacked"
              fill="outline"
              className={`wl-select-input ${noMargin ? "no-margin" : ""} ${
                hasErrors && "error"
              } ${className !== undefined ? className : ""}`}
              // label={label}
              placeholder={placeholder}
              value={value}
              onIonChange={({ detail: { value } }) => {
                onChange(value);
                if (onCustomChange !== undefined) {
                  onCustomChange(value);
                }
              }}
              interface={selectInterface}
            >
              {selectOptions.map((option, index) => {
                return (
                  <IonSelectOption
                    key={`select-option-${option.value}-${index}`}
                    value={option.value}
                  >
                    {option.label}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
            {hasErrors && (
              <span className="wl-select-error-helper wl-body-5 danger">
                {hasErrors ? errorMsg : "Input invalid"}
              </span>
            )}
          </div>
        );
      }}
      rules={rules}
    />
  );
};
