import isEmpty from "lodash/isEmpty";
import React from "react";

import "./WLDivider.scss";
import { IonLabel } from "@ionic/react";

export const WLDivider = ({
  className,
  color,
  hasPadding,
  style,
  text,
}: {
  className?: string;
  color?: "light" | "grey" | "dark" | "gold";
  hasPadding?: boolean;
  style?: any;
  text?: string;
}) => {
  const divider = (
    <div
      style={style}
      className={`${!isEmpty(className) ? className : ""} ${
        color !== undefined ? color : ""
      } ${!!hasPadding ? "ion-padding-top ion-margin-bottom" : ""} divider`}
    />
  );

  return !text ? (
    divider
  ) : (
    <div className="textContainer">
      {divider} <IonLabel className={`wl-body-3 ${color}`}>{text}</IonLabel>{" "}
      {divider}
    </div>
  );
};
