import compact from "lodash/compact";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import Stripe from "stripe";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import useWebSignupStore from "./store";
import { bugNotif } from "../../api/bugsnag";
import { getWLPromoEntries, isPromoValid } from "../../services/promo";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { useFormsStore } from "../../../Common/store/common";
import {
  createSubscriptionSinglePage,
  validateStripePromo,
} from "../../api/stripe";
import { getErrorMsg, useEffectOnlyOnce } from "../../../Common/utils/common";
import {
  PriceDiscoveryVersion,
  ViewWLSubscriptionsTiers,
} from "../../../Common/models/subscriptions";
import {
  ENVIRONMENTS,
  STRIPE_SUBSCRIPTION_ID_MAP,
  STRIPE_SUBSCRIPTION_ID_MAP_DEV_STG,
} from "../../../Common/config";
import {
  addtoCartKlaviyoEvent,
  identifyKlaviyoCompleteProfile,
  sendFbqCart,
} from "../../services/user";
import { getUpcomingGiveaways } from "../../services/giveaways";
import { getSubscriptionListStripe } from "../../services/subscription";
import { orderBy } from "lodash";
import { identifySessionStack } from "../../routes/utils";
import {
  ReferralBonus,
  ReferralConfig,
  ReferralPowerUpTypeSourceMap,
} from "../../../Common/models/config";
import { getWlConfig } from "../../services/config";

export const useWebSignupProvider = () => {
  const { setFormLoading } = useFormsStore();

  const {
    signInProvider,
    setFirstName,
    setLastName,
    setEmailAddress,
    setPassword,
    setUserState,
    setPhoneNumber,
    setUserId,
    validPromoCode,
    validStripeCoupon,
    selectedSubscription,
    setStripeClientSecretId,
    setSubscriptionIdToPay,
  } = useWebSignupStore();

  const [error, setError] = useState("");
  const [redirectToApp, setRedirectToApp] = useState(false);

  const useFormMethods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
  });
  const { handleSubmit } = useFormMethods;

  const submit = async (data: any) => {
    setFormLoading(true);
    const { firstName, lastName, phoneNumber, emailAddress, password, suburb } =
      data as {
        firstName: string;
        lastName: string;
        phoneNumber: string;
        emailAddress: string;
        password: string;
        suburb: string;
      };

    setFirstName(firstName);
    setLastName(lastName);
    setPhoneNumber(phoneNumber);
    setEmailAddress(emailAddress);
    setPassword(password);
    setUserState(suburb);
    try {
      let source = "unknown";
      let campaign = "unknown";
      let content = "unknown";
      let medium = "unknown";
      const fbSource = new URLSearchParams(window.location.search).get(
        "fbclid"
      );

      if (!isNull(fbSource) && !isEmpty(fbSource)) {
        source = "Facebook";
      }

      const tiktokSource = new URLSearchParams(window.location.search).get(
        "ttclid"
      );

      if (!isNull(tiktokSource) && !isEmpty(tiktokSource)) {
        source = "Tiktok";
      }

      const urlSource = new URLSearchParams(window.location.search).get(
        "utm_source"
      );

      if (!isNull(urlSource) && !isEmpty(urlSource)) {
        source = urlSource;
      }

      const campaignSource = new URLSearchParams(window.location.search).get(
        "utm_campaign"
      );

      if (!isNull(campaignSource) && !isEmpty(campaignSource)) {
        campaign = campaignSource;
      }

      const contentSource = new URLSearchParams(window.location.search).get(
        "utm_content"
      );

      if (!isNull(contentSource) && !isEmpty(contentSource)) {
        content = contentSource;
      }

      const mediumSource = new URLSearchParams(window.location.search).get(
        "utm_medium"
      );

      if (!isNull(mediumSource) && !isEmpty(mediumSource)) {
        medium = mediumSource;
      }

      const referredByMemberId = new URLSearchParams(
        window.location.search
      ).get("referredByMemberId");

      const referralBonusLookUpKey = new URLSearchParams(
        window.location.search
      ).get("referralBonusLookUpKey");

      const result = await createSubscriptionSinglePage({
        ...data,
        emailAddress,
        password,
        source,
        campaign,
        content,
        medium,
        referredByMemberId,
        referralBonusLookUpKey,
        signInProvider: signInProvider || "in-app",
        priceId: selectedSubscription?.stripePriceId,
        couponId: validStripeCoupon?.id,
        promoCode: validPromoCode || "",
        ...(selectedSubscription?.alternativePriceVersion !== undefined
          ? {
              alternativePriceVersion:
                selectedSubscription.alternativePriceVersion,
            }
          : {}),
      });
      const { clientSecret, subscriptionId, userId } = result.data;

      setStripeClientSecretId(clientSecret);
      setSubscriptionIdToPay(subscriptionId);
      // submitPayment();

      // setStripeId(stripeId);
      setUserId(userId);
      identifySessionStack(userId);
      // await trackWebSignUpStep({
      //   emailAddress,
      //   step: "completeProfile",
      //   subscription: null,

      //   version: 6,
      // });
      await identifyKlaviyoCompleteProfile(firstName, lastName, phoneNumber);
      // setFormLoading(false);
    } catch (error) {
      setFormLoading(false);
      setStripeClientSecretId(undefined);
      setSubscriptionIdToPay(undefined);
      const { errorMsg, rawErrorMsg } = getErrorMsg(error);
      if (
        errorMsg ===
        "User is already existing. please login via winners locker app instead."
      ) {
        setRedirectToApp(true);
      } else {
        setError(errorMsg);
        bugNotif("StepCompleteProfile", rawErrorMsg);
      }
    }
  };

  return {
    useFormMethods,
    error,
    redirectToApp,
    setRedirectToApp,
    onSubmit: handleSubmit(submit),
  };
};

export const useWebSignupPromoCodeProvider = () => {
  const { setFormLoading } = useFormsStore();

  const {
    validPromoCodePromo,
    selectedSubscription,
    setValidPromoCode,
    setValidStripeCoupon,
    setValidPromoCodePromo,

    setPromoEntries,
  } = useWebSignupStore();

  // to validate internal promo
  const validateWLPromoCode = async (promoCode: string) => {
    setValidPromoCodePromo(undefined);
    setValidPromoCode(undefined);
    try {
      const result = await isPromoValid({
        code: promoCode,
        forSignUp: true,
      });
      const testedPromoCode = !isNull(result) ? result.code : "";
      setValidPromoCodePromo(result);
      setValidPromoCode(testedPromoCode);
      console.log("got valid promo!", testedPromoCode);
      return !isEmpty(testedPromoCode);
    } catch (error) {
      setValidPromoCodePromo(null);
      setValidPromoCode("");
      bugNotif("debouncedValidatePromo", getErrorMsg(error).rawErrorMsg);
      return false;
    }
  };

  // to validate stripe coupon
  const validateStripePromoCode = async (promoCode: string) => {
    setValidStripeCoupon(undefined);
    try {
      const result = await validateStripePromo({
        promoCode,
      });
      const coupon = result.data.coupon as Stripe.Coupon;
      console.log(
        "now checking stripe coupon against ",
        coupon,
        selectedSubscription
      );
      if (
        coupon.applies_to !== undefined &&
        selectedSubscription !== undefined &&
        !isNull(selectedSubscription) &&
        coupon.applies_to.products.indexOf(selectedSubscription.docId) === -1
      ) {
        setValidStripeCoupon(null);
        return false;
      }
      // console.log("got valid stripe promo!", coupon);

      setValidStripeCoupon(coupon);

      return true;
    } catch (error) {
      setValidStripeCoupon(null);
      bugNotif("debouncedValidatePromo", getErrorMsg(error).rawErrorMsg);
      return false;
    }
  };

  // to validate promo and stripe coupon simultaneously
  const validatePromoCode = async (_promoCode = "") => {
    setFormLoading(true);
    const code = (_promoCode || "").trim().toUpperCase();
    // console.log("checking --- ", { code, overrideCode });
    const validationResults = await Promise.all([
      validateWLPromoCode(code),
      validateStripePromoCode(code),
    ]);

    setFormLoading(false);

    const result =
      compact(validationResults).length > 0 ||
      isEmpty(code) ||
      "Invalid promo code";
    if (typeof result === "string") {
      // setError(result);
    } else {
      // clearErrors(); // recheck!
    }
    console.log("got result result", result, validationResults, code);
    // refresh stripe form here get another clientsecretid after update

    return result;
  };

  // Initial handling if promo code is not undefined on load
  useEffectOnlyOnce(() => {
    const promoCode = new URLSearchParams(window.location.search).get("promo");
    if (!isNull(promoCode) && !isEmpty(promoCode)) {
      const promo = promoCode.toUpperCase().trim();
      // setValue(promoField.name, promo);
      // trigger(promoField.name);
      console.log("will check promo from query");
      validatePromoCode(promo);
    } else {
      setValidPromoCode("");
      setValidStripeCoupon(null);
      setValidPromoCodePromo(null);
    }
  });

  useEffect(() => {
    if (
      !isNull(validPromoCodePromo) &&
      validPromoCodePromo !== undefined &&
      !isNull(selectedSubscription) &&
      selectedSubscription !== undefined
    ) {
      const promoEntries = getWLPromoEntries(
        validPromoCodePromo,
        selectedSubscription
      );
      setPromoEntries(promoEntries);
    } else {
      setPromoEntries(undefined);
    }
  }, [validPromoCodePromo, selectedSubscription]);
};

export const useUpcomingGiveawaysProvider = () => {
  const { setCurrentGiveaway, setUpcomingGiveaways } = useWebSignupStore();

  const { data, error } = useQuery({
    queryKey: ["GET_UPCOMMING_GIVEAWAYS_LIST"],
    queryFn: async () => {
      const resp = await getUpcomingGiveaways();
      return resp;
    },
    refetchOnWindowFocus: true,
  });
  useEffect(() => {
    if (!isNull(error)) {
      bugNotif(
        "useCashGiveawaysProvider - getGiveaways",
        getErrorMsg(error).rawErrorMsg
      );
    }
  }, [error]);

  useEffect(() => {
    if (data !== undefined) {
      setCurrentGiveaway(data[0]);
      setUpcomingGiveaways(data.splice(1));
    }
  }, [data]);
};

export const useSubscriptionsProvider = () => {
  const pageParams = useParams();
  const { setSubscriptionList, selectedSubscription, setSelectedSubscription } =
    useWebSignupStore();

  // GET OPTIONS FOR SUBSCRIPTION
  const { data: subscriptionList, error: subscriptionListError } = useQuery({
    queryKey: ["GET_SUBSCRIPTION_WEB_SIGNUP"],
    queryFn: async () => {
      // const priceDiscoveryVersion = new URLSearchParams(
      //   window.location.search
      // ).get("pdv");

      const resp = await getSubscriptionListStripe();

      return orderBy(resp, (sub) => sub.price, "asc");
    },
    refetchOnWindowFocus: false,
  });

  // To handle subscription list get error
  useEffect(() => {
    if (!isNull(subscriptionListError)) {
      bugNotif(
        "StepEmailSetup - getSubscriptions",
        getErrorMsg(subscriptionListError).rawErrorMsg
      );
    }
  }, [subscriptionListError]);

  // to handle update subscription selection. Mostly used by upsell component.
  // Can be used also when changing plans
  const updateSelectedSubscription = (
    subscription: ViewWLSubscriptionsTiers
  ) => {
    setSelectedSubscription(subscription);

    const pid = subscription.docId;
    const value = subscription.price;
    sendFbqCart("Membership", pid, value);
    // sendSnapAddToCart(emailAddress, "Membership", pid, value);
    // sendttqAddToCart(emailAddress, pid, value);
    addtoCartKlaviyoEvent(pid, value);
  };

  // To handle selected subscription.
  useEffect(() => {
    if (subscriptionList !== undefined) {
      if (selectedSubscription === undefined || isNull(selectedSubscription)) {
        const { subscriptionKey } = pageParams as {
          subscriptionKey: string | undefined;
        };

        const selectedSubscriptionId =
          import.meta.env.MODE === ENVIRONMENTS.production
            ? STRIPE_SUBSCRIPTION_ID_MAP[subscriptionKey || "pro"]
            : STRIPE_SUBSCRIPTION_ID_MAP_DEV_STG[subscriptionKey || "pro"];

        updateSelectedSubscription(
          find(
            subscriptionList,
            (sub) => sub.docId === selectedSubscriptionId
          ) || subscriptionList[1]
        );

        setSubscriptionList(subscriptionList);
      }
    }
  }, [subscriptionList]);
};

export const useGetReferralEntries = () => {
  const [referralParam, setReferralParam] = useState<
    { referredByMemberId: string; referralBonusLookUpKey: string } | undefined
  >(undefined);
  const [referralConfig, setReferralConfig] = useState<
    ReferralConfig | undefined
  >(undefined);
  const { selectedSubscription, setReferredBonusEntries } = useWebSignupStore();

  const { data, error } = useQuery({
    queryKey: ["WLCONFIG", referralParam],
    queryFn: async () => {
      if (referralParam) {
        const resp = await getWlConfig();
        return resp;
      }
    },
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (!isNull(error)) {
      bugNotif(
        "useGetReferralEntries - getWLConfigProvider",
        getErrorMsg(error).rawErrorMsg
      );
    }
  }, [error]);

  useEffect(() => {
    if (!!data && referralParam !== undefined) {
      const referralConfig = data.referralConfig.find(
        (item) => item.lookUpKey === referralParam.referralBonusLookUpKey
      );

      setReferralConfig(referralConfig);
    }
  }, [data]);

  const getRefferedBonusEntries = (
    { type, value }: ReferralBonus,
    entriesPerMonth: number
  ) => {
    switch (type) {
      case 1:
        return {
          title: `Bonus Power Up entries`,
          promoText: `free $${ReferralPowerUpTypeSourceMap[value].value} Power Up`,
          value: ReferralPowerUpTypeSourceMap[value].entries,
        };
      case 2:
        return {
          title: "Bonus entries",
          promoText: `additional ${value} entries`,
          value,
        };
      case 3:
        return {
          title: `Bonus ${value}x entry multiplier`,
          promoText: `${value}X entries`,
          value: entriesPerMonth * (value - 1),
        };
      default:
        return {
          title: "Bonus entries",
          value,
          promoText: `additional ${value} entries`,
        };
    }
  };

  useEffect(() => {
    if (
      referralConfig !== undefined &&
      selectedSubscription !== undefined &&
      !isNull(selectedSubscription)
    ) {
      const referedBonusEntries = getRefferedBonusEntries(
        referralConfig.referredBonus,
        selectedSubscription.entriesPerMonth
      );
      const twoWayBonusEntries = getRefferedBonusEntries(
        referralConfig.twoWayBonus,
        selectedSubscription.entriesPerMonth
      );

      setReferredBonusEntries({
        referedBonusEntries,
        twoWayBonusEntries,
        referedTotalEntries:
          referedBonusEntries.value + twoWayBonusEntries.value,
      });
    } else {
      setReferredBonusEntries(undefined);
    }
  }, [referralConfig, selectedSubscription]);

  useEffect(() => {
    const referredByMemberId = new URLSearchParams(window.location.search).get(
      "referredByMemberId"
    );

    const referralBonusLookUpKey = new URLSearchParams(
      window.location.search
    ).get("referralBonusLookUpKey");

    if (!isNull(referredByMemberId) && !isNull(referralBonusLookUpKey)) {
      setReferralParam({
        referredByMemberId,
        referralBonusLookUpKey,
      });
    }
  }, []);
};
