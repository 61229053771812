import StripeJS from "stripe";
import { create } from "zustand";
import { ViewWLSubscriptionsTiers } from "../../../Common/models/subscriptions";
import { Promo } from "../../../Common/models/promos";
import { SignInProvider } from "../../../Common/models/user";
import { Giveaway } from "../../../Common/models/giveaways";

export const STEPS = ["Join", "Profile", "Enter"];

interface iWebSignUpSteps {
  [step: string]: number;
}
export const WEB_SIGNUP_STEPS = {
  email: 1,
  completeProfile: 2,
  subscription: 3,
} satisfies iWebSignUpSteps;

interface ReferredBonusEntries {
  referedBonusEntries: {
    title: string;
    value: number;
    promoText: string;
  };
  twoWayBonusEntries: {
    title: string;
    value: number;
    promoText: string;
  };
  referedTotalEntries: number;
}

interface WebSignUpState {
  // Index Page
  step: number;
  setStep: (step: number) => void;

  // 1st step
  emailAddress: string;
  setEmailAddress: (value: string) => void;

  signInProvider: SignInProvider | null;
  setSignInProvider: (state: SignInProvider) => void;

  password: string;
  setPassword: (value: string) => void;

  // 2nd step
  firstName: string;
  setFirstName: (value: string) => void;

  lastName: string;
  setLastName: (value: string) => void;

  phoneNumber: string;
  setPhoneNumber: (value: string) => void;

  userState: string;
  setUserState: (value: string) => void;

  // last step
  stripeId: string;
  setStripeId: (value: string) => void;

  userId: string;
  setUserId: (value: string) => void;

  subscriptionList: ViewWLSubscriptionsTiers[];
  setSubscriptionList: (value: ViewWLSubscriptionsTiers[]) => void;

  selectedSubscription: undefined | null | ViewWLSubscriptionsTiers;
  setSelectedSubscription: (value: null | ViewWLSubscriptionsTiers) => void;

  validPromoCode: string | undefined;
  setValidPromoCode: (value: string | undefined) => void;

  validPromoCodePromo: Promo | null | undefined;
  setValidPromoCodePromo: (value: Promo | null | undefined) => void;

  promoEntries: undefined | number;
  setPromoEntries: (value: undefined | number) => void;

  lockerWinEntries: undefined | number;
  setLockerWinEntries: (value: undefined | number) => void;

  lockerWinEntriesExpireDt: undefined | number;
  setLockerWinEntriesExpireDt: (value: undefined | number) => void;

  referredBonusEntries: undefined | ReferredBonusEntries;
  setReferredBonusEntries: (value: undefined | ReferredBonusEntries) => void;

  validStripeCoupon: undefined | null | StripeJS.Coupon;
  setValidStripeCoupon: (value: StripeJS.Coupon | null | undefined) => void;

  stripeClientSecretId: undefined | null | string;
  setStripeClientSecretId: (value: undefined | null | string) => void;

  subscriptionIdToPay: undefined | null | string;
  setSubscriptionIdToPay: (value: undefined | null | string) => void;

  submitPayment: () => void;
  setSubmitPayment: (func: () => void) => void;

  currentGiveaway: Giveaway | null | undefined;
  setCurrentGiveaway: (value: Giveaway | null | undefined) => void;

  upcomingGiveaways: Giveaway[] | null | undefined;
  setUpcomingGiveaways: (value: Giveaway[] | null | undefined) => void;

  membershipsModalOpen: boolean;
  setMembershipsModalOpen: (value: boolean) => void;
}

const useWebSignupStore = create<WebSignUpState>((set) => ({
  step: WEB_SIGNUP_STEPS.email,
  setStep: (step) => set({ step }),

  emailAddress: "",
  setEmailAddress: (emailAddress) => set({ emailAddress }),

  signInProvider: null,
  setSignInProvider: (signInProvider) => set({ signInProvider }),

  password: "",
  setPassword: (password) => set({ password }),

  firstName: "",
  setFirstName: (firstName) => set({ firstName }),

  lastName: "",
  setLastName: (lastName) => set({ lastName }),

  phoneNumber: "",
  setPhoneNumber: (phoneNumber) => set({ phoneNumber }),

  userState: "",
  setUserState: (userState) => set({ userState }),

  stripeId: "",
  setStripeId: (stripeId) => set({ stripeId }),

  userId: "",
  setUserId: (userId) => set({ userId }),

  subscriptionList: [],
  setSubscriptionList: (subscriptionList) => set({ subscriptionList }),

  selectedSubscription: undefined,
  setSelectedSubscription: (selectedSubscription) =>
    set({ selectedSubscription }),

  validPromoCode: undefined,
  setValidPromoCode: (validPromoCode) => set({ validPromoCode }),

  validPromoCodePromo: undefined,
  setValidPromoCodePromo: (validPromoCodePromo) => set({ validPromoCodePromo }),

  promoEntries: undefined,
  setPromoEntries: (promoEntries) => set({ promoEntries }),

  lockerWinEntries: undefined,
  setLockerWinEntries: (lockerWinEntries) => set({ lockerWinEntries }),

  lockerWinEntriesExpireDt: undefined,
  setLockerWinEntriesExpireDt: (lockerWinEntriesExpireDt) =>
    set({ lockerWinEntriesExpireDt }),

  referredBonusEntries: undefined,
  setReferredBonusEntries: (referredBonusEntries) =>
    set({ referredBonusEntries }),

  validStripeCoupon: undefined,
  setValidStripeCoupon: (validStripeCoupon) => set({ validStripeCoupon }),

  stripeClientSecretId: undefined,
  setStripeClientSecretId: (stripeClientSecretId) =>
    set({ stripeClientSecretId }),

  subscriptionIdToPay: undefined,
  setSubscriptionIdToPay: (subscriptionIdToPay) => set({ subscriptionIdToPay }),

  submitPayment: () => {},
  setSubmitPayment: (submitPayment) => set({ submitPayment }),

  currentGiveaway: undefined,
  setCurrentGiveaway: (currentGiveaway) => set({ currentGiveaway }),

  upcomingGiveaways: undefined,
  setUpcomingGiveaways: (upcomingGiveaways) => set({ upcomingGiveaways }),

  membershipsModalOpen: false,
  setMembershipsModalOpen: (membershipsModalOpen) =>
    set({ membershipsModalOpen }),
}));

export default useWebSignupStore;
