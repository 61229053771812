import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import React, { useState } from "react";
import { Controller, useFormContext, RegisterOptions } from "react-hook-form";
import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
} from "@ionic/react";

import "./WLFormMobileNumberInput.scss";
import countriesJson from "../../../constants/countries.json";
import {
  WLCountryCodes,
  Country,
  getAllCountries,
} from "./WLCountryCodes/WLCountryCodes";
import ArrowDownIcon from "../../../assets/images/arrow-down.svg?react";
import { ENVIRONMENTS } from "../../../config";

// Get all props from IonInput then removed className and onIonChange to override
type OverriddentIonInputProps = Omit<
  React.ComponentProps<typeof IonInput>,
  "className" | "onIonChange" | "value"
>;

export interface WLFormMobileNumberInputProps
  extends Partial<OverriddentIonInputProps> {
  name: string;
  className?: string;
  rules?: RegisterOptions;
  label?: string;
}

/**
 * !Before using this component:
 * - Make sure parent is wrapped in <FormProvider></FormProvider>
 * - Make sure useForm methods is passed in FormProvider. Example:
 *  const useFormMethods = useForm({
 *   mode: "all",
 *   reValidateMode: "onChange",
 * });
 * <FormProvider {...useFormMethods}>
 *  <WLFormMobileNumberInput/>
 */

const DEFAULT_NUMBER = (
  import.meta.env.MODE === ENVIRONMENTS.production
    ? {
        code: "AU",
        flag: countriesJson.AU.flag,
        callingCode: countriesJson.AU.callingCode,
        name: countriesJson.AU.name.common,
      }
    : {
        code: "PH",
        flag: countriesJson.PH.flag,
        callingCode: countriesJson.PH.callingCode,
        name: countriesJson.PH.name.common,
      }
) as Country;
export const WLFormMobileNumberInput = (
  props: WLFormMobileNumberInputProps
) => {
  const { name, rules, label, className, role, disabled, placeholder } = props;

  const [selectedCountryCode, setSelectedCountryCode] =
    useState(DEFAULT_NUMBER);

  const [showCountryCodesModal, setShowCountryCodesModal] = useState(false);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const setAndCheckCountryCode = (phoneNumber: string) => {
    const phoneCountryCode = phoneNumber.slice(0, 3);
    const selectedCountryCode = find(
      getAllCountries(),
      (country) => country.callingCode === phoneCountryCode
    );
    if (selectedCountryCode !== undefined) {
      setSelectedCountryCode(selectedCountryCode);
    }
  };
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <div className="wl-form-mobile-number-input-content">
      {label && (
        <IonLabel
          className={`wl-form-mobile-number-input-label ion-text-start wl-body-6 blue500${
            disabled && "gray"
          } ${rules?.required && "required"}`}
        >
          {label}
        </IonLabel>
      )}
      <IonItem
        className={`wl-form-mobile-number-input-item ion-no-padding ion-no-margin ${
          !isEmpty(className) ? className : ""
        } ${hasFocus ? "has-focus" : ""} ${!!errors[name] ? "error" : ""}`}
        lines="none"
      >
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange, onBlur } }) => {
            const valueWithoutCode = (value || "").replace(
              `${selectedCountryCode.callingCode}`,
              ""
            );
            setAndCheckCountryCode(valueWithoutCode);

            const countriesOnChange = (selectedCountry: Country) => {
              setSelectedCountryCode(selectedCountry);
              setShowCountryCodesModal(false);
              onChange(`${selectedCountry.callingCode}${valueWithoutCode}`);
            };

            return (
              <div className="wl-form-mobile-number-input-container">
                {/* <IonButton
                  disabled={disabled}
                  className={`wl-form-mobile-number-country-code-button ion-no-margin  ${
                    !!errors[name] ? "error" : ""
                  }`}
                  onClick={() => {
                    setShowCountryCodesModal(true);
                  }}
                >
                  <img
                    className="wl-form-mobile-number-country-code-flag"
                    src={selectedCountryCode.flag}
                    alt="country-flag"
                  />
                  <ArrowDownIcon className="wl-form-mobile-number-country-code-arrow-down" />
                  <div className="country-border" />
                  <IonLabel className="calling-code">
                    {selectedCountryCode.callingCode}
                  </IonLabel>
                </IonButton> */}
                <div
                  className={`wl-form-mobile-number-country-code-button ion-no-margin  ${
                    !!errors[name] ? "error" : ""
                  }`}
                >
                  <img
                    className="wl-form-mobile-number-country-code-flag"
                    src={selectedCountryCode.flag}
                    alt="country-flag"
                  />
                  {/* <ArrowDownIcon className="wl-form-mobile-number-country-code-arrow-down" /> */}
                  <div className="country-border" />
                  <IonLabel className="calling-code">
                    {selectedCountryCode.callingCode}
                  </IonLabel>
                </div>
                <IonInput
                  mode="md"
                  disabled={disabled}
                  className={`wl-form-mobile-number-input ${
                    !!errors[name] ? "error" : ""
                  }`}
                  // clearInput
                  type="tel"
                  counter={false}
                  placeholder={placeholder}
                  value={valueWithoutCode}
                  onBlur={() => {
                    setHasFocus(false);
                    onBlur();
                  }}
                  onIonBlur={() => {
                    setHasFocus(false);
                    onBlur();
                  }}
                  onIonFocus={() => {
                    setHasFocus(true);
                  }}
                  //HANDLE AUTO FORMAT OF PHONE NUMBER
                  onIonInput={
                    ({ detail: { value } }) =>
                      // should handle change of country code if from a known number
                      {
                        // console.log({
                        //   initial: `${selectedCountryCode.callingCode}${
                        //     value || ""
                        //   }`,
                        //   final: `${selectedCountryCode.callingCode}${(
                        //     value || ""
                        //   ).replace(/\D+/g, "")}`,
                        // });
                        onChange(
                          `${selectedCountryCode.callingCode}${value || ""}`
                        );
                      }
                    // onChange(value)
                  }
                  {...(!!role && { role })}
                />

                <IonModal
                  isOpen={showCountryCodesModal}
                  onDidDismiss={() => {
                    setShowCountryCodesModal(false);
                  }}
                  color="tertiary"
                >
                  <WLCountryCodes
                    onChange={countriesOnChange}
                    onCancel={() => {
                      setShowCountryCodesModal(false);
                    }}
                  />
                </IonModal>
              </div>
            );
          }}
          rules={rules}
        />
      </IonItem>
      {!!errors[name] && (
        <IonLabel
          className="wl-form-mobile-number-input-error-badge ion-text-start wl-body-5 danger"
          {...(!!role && { role: `error-${role}` })}
        >
          {!!errors && errors[name] && !isEmpty(errors[name]?.message)
            ? (errors[name]!.message as string)
            : "Input invalid"}
        </IonLabel>
      )}
    </div>
  );
};
