import cx from "classnames";
import { memo, useEffect, useMemo, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import s from "./StripePaymentForm.module.scss";
import { stripeKey } from "../../../firebase";
import StripePaymentForm from "./StripePaymentForm";
import { IonLabel } from "@ionic/react";
import { WLSkeleton } from "../../../../Common/components/WLSkeleton/WLSkeleton";
import useWebSignupStore from "../store";
import { isNull } from "lodash";
import { getStripePriceValueWithPromo } from "../../../services/promo";

const stripePromise = loadStripe(stripeKey);

const StripeForm = () => {
  const [resetStripeByAmount, setResetStripeByAmount] = useState(true);
  const { selectedSubscription, validStripeCoupon, doubleForHalfOffAvailed } =
    useWebSignupStore();

  const amount = useMemo(() => {
    if (
      selectedSubscription === undefined ||
      validStripeCoupon === undefined ||
      isNull(selectedSubscription)
    ) {
      return;
    }

    if (!isNull(validStripeCoupon)) {
      return (
        Math.round(
          getStripePriceValueWithPromo(
            selectedSubscription.price,
            validStripeCoupon
          ) * 100
        ) * (doubleForHalfOffAvailed ? 1.5 : 1)
      );
    } else {
      return (
        Math.round(selectedSubscription.price * 100) *
        (doubleForHalfOffAvailed ? 1.5 : 1)
      );
    }
  }, [selectedSubscription, validStripeCoupon, doubleForHalfOffAvailed]);

  useEffect(() => {
    setResetStripeByAmount(false);
    setTimeout(() => {
      setResetStripeByAmount(true);
    }, 100);
  }, [amount]);

  return (
    <div className={s.container}>
      {amount !== undefined && resetStripeByAmount ? (
        <>
          <IonLabel className={cx(s.title, "wl-medium primary normal")}>
            PAYMENT
          </IonLabel>

          <Elements
            stripe={stripePromise}
            options={{
              locale: "en-AU",
              amount,
              currency: "aud",
              payment_method_types: ["card", "link"],
              mode: "payment",
            }}
          >
            <StripePaymentForm />
          </Elements>
        </>
      ) : (
        <WLSkeleton width="100%" height="300px" />
      )}
    </div>
  );
};

export default memo(StripeForm);
