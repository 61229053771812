import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";

import s from "./StripeExpressPaymentForm.module.scss";
import { memo, useState } from "react";
import {
  StripeExpressCheckoutElementConfirmEvent,
  StripeExpressCheckoutElementReadyEvent,
  StripePaymentElementOptions,
} from "@stripe/stripe-js";
import {
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { IonToast } from "@ionic/react";
import { useFormsStore } from "../../../../Common/store/common";
import useWebSignupStore from "../store";
import { getErrorMsg, isMobile } from "../../../../Common/utils/common";
import { bugNotif } from "../../../api/bugsnag";
import { WEB_DOWNLOAD_APP } from "../../../constants/routes";
import {
  identifyKlaviyoUserEmail,
  sendTTQCheckoutEvent,
  sendTTQViewEvent,
  startCheckoutKlaviyoEvent,
  trackWebSignUpStep,
  viewProductKlaviyoEvent,
} from "../../../services/user";
import { createSubscriptionExpressCheckout } from "../../../api/stripe";
import { createKlaviyoEmail } from "../../../api/klaviyo";
import { trackUserReferral } from "../../../api/user";

const ttqEventSent = false;
const StripeExpressPaymentFormInternal = ({
  onReady,
  setRedirectToApp,
}: {
  onReady: (event: StripeExpressCheckoutElementReadyEvent) => void;
  setRedirectToApp: (data: boolean) => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const setFormLoading = useFormsStore((state) => state.setFormLoading);

  const [error, setError] = useState("");

  const PAYMENT_ELEMENT_OPTIONS = {
    billingDetails: {
      address: "never",
      postalCode: "never",
    },

    terms: {
      card: "never",
      googlePay: "never",
      applePay: "never",
    },
  } as StripePaymentElementOptions;
  const {
    selectedSubscription,

    validStripeCoupon,
    validPromoCode,
  } = useWebSignupStore();

  const onCreateKlaviyoEmail = async (emailAddress: string) => {
    try {
      await createKlaviyoEmail(emailAddress);
    } catch (error) {
      bugNotif("onCreateKlaviyoEmail", getErrorMsg(error).rawErrorMsg);
    }
  };

  const processValidEmail = async (emailAddress: string) => {
    console.log("processValidEmail ----- start", emailAddress);
    const signUpStore = useWebSignupStore.getState();
    const subscription = signUpStore.selectedSubscription;

    identifyKlaviyoUserEmail(emailAddress);

    if (subscription !== undefined && !isNull(subscription)) {
      const promoCode = new URLSearchParams(window.location.search).get(
        "promo"
      );
      const promo =
        !isNull(promoCode) && !isEmpty(promoCode)
          ? promoCode.toUpperCase().trim()
          : null;
      await sendTTQViewEvent(emailAddress, subscription);
      await viewProductKlaviyoEvent(subscription.docId);
      await trackWebSignUpStep({
        emailAddress,
        step: "viewed",
        version: 7,
        subscription: subscription.name,
        promoCode: promo,
        revenue: null,
      });

      const referredByMemberId = new URLSearchParams(
        window.location.search
      ).get("referredByMemberId");

      const referralBonusLookUpKey = new URLSearchParams(
        window.location.search
      ).get("referralBonusLookUpKey");
      if (!isNull(referredByMemberId) && !isNull(referralBonusLookUpKey)) {
        await trackUserReferral({
          referralMemberId: referredByMemberId,
          wlReferralConfigLookupKey: referralBonusLookUpKey,
          referredEmailAddress: emailAddress,
          revenue: 0,
        });
      }
    }

    await onCreateKlaviyoEmail(emailAddress);
    // await trackWebSignUpStep(emailAddress, "viewed", 7);
    console.log("processValidEmail ----- done", emailAddress);
  };

  const onConfirm = async ({
    paymentFailed,
    billingDetails,
  }: StripeExpressCheckoutElementConfirmEvent) => {
    try {
      if (!stripe) {
        return setError("Form is not yet ready. Please try again.");
      }

      if (isNull(elements)) {
        return setError("Something went wrong. Please try again.");
      }

      if (!selectedSubscription || !selectedSubscription.stripePriceId) {
        return setError("Something went wrong. Please try again.");
      }

      const { email, phone, name } = billingDetails!;

      if (!email) {
        return setError("Email address is missing");
      }

      // if (!phone) {
      //   return setError("Phone number is missing");
      // }

      setFormLoading(true);

      let source = "unknown";
      let campaign = "unknown";
      let content = "unknown";
      let medium = "unknown";
      const fbSource = new URLSearchParams(window.location.search).get(
        "fbclid"
      );

      if (!isNull(fbSource) && !isEmpty(fbSource)) {
        source = "Facebook";
      }

      const tiktokSource = new URLSearchParams(window.location.search).get(
        "ttclid"
      );

      if (!isNull(tiktokSource) && !isEmpty(tiktokSource)) {
        source = "Tiktok";
      }

      const urlSource = new URLSearchParams(window.location.search).get(
        "utm_source"
      );

      if (!isNull(urlSource) && !isEmpty(urlSource)) {
        source = urlSource;
      }

      const campaignSource = new URLSearchParams(window.location.search).get(
        "utm_campaign"
      );

      if (!isNull(campaignSource) && !isEmpty(campaignSource)) {
        campaign = campaignSource;
      }

      const contentSource = new URLSearchParams(window.location.search).get(
        "utm_content"
      );

      if (!isNull(contentSource) && !isEmpty(contentSource)) {
        content = contentSource;
      }

      const mediumSource = new URLSearchParams(window.location.search).get(
        "utm_medium"
      );

      if (!isNull(mediumSource) && !isEmpty(mediumSource)) {
        medium = mediumSource;
      }

      const referredByMemberId = new URLSearchParams(
        window.location.search
      ).get("referredByMemberId");

      const referralBonusLookUpKey = new URLSearchParams(
        window.location.search
      ).get("referralBonusLookUpKey");

      await processValidEmail(email);
      console.log("will submit element!");

      const { error: submitError } = await elements.submit();
      if (submitError) {
        console.log({
          submitError,
        });
        setError(submitError.message || "");
        setFormLoading(false);
        return;
      }

      console.log("will now create express checkout");

      const result = await createSubscriptionExpressCheckout({
        emailAddress: email,
        phoneNumber: phone || "",
        source,
        campaign,
        content,
        medium,
        referredByMemberId,
        referralBonusLookUpKey,
        priceId: selectedSubscription.stripePriceId!,
        couponId: validStripeCoupon?.id || "",
        promoCode: validPromoCode || "",
        ...(selectedSubscription?.alternativePriceVersion !== undefined
          ? {
              alternativePriceVersion:
                selectedSubscription.alternativePriceVersion,
            }
          : {}),
      });

      console.log("done creating checkout", result);

      const { clientSecret, userId } = result.data;

      const emailAddress = email;
      const promoCode = new URLSearchParams(window.location.search).get(
        "promo"
      );
      const promo =
        !isNull(promoCode) && !isEmpty(promoCode)
          ? promoCode.toUpperCase().trim()
          : null;

      const currentUrl = window.location.href; // Gets the current page's URL
      const parsedUrl = new URL(currentUrl);
      let domain = parsedUrl.hostname;
      // const paramFullName = `${firstName} ${lastName}`.split(" ").join("_");
      let returnUrl = `https://${domain}${WEB_DOWNLOAD_APP}/?userId=${userId}&pid=${
        selectedSubscription.docId
      }&emailAddress=${emailAddress}&promoCode=${promo || "null"}&signUpVersion=7${
        name ? `&signedUpFullName=${name}` : ""
      }&expressCheckout=true&phoneNumber=${phone || ""}`;
      if (domain === "localhost") {
        returnUrl = `http://${domain}:5173${WEB_DOWNLOAD_APP}/?userId=${userId}&pid=${
          selectedSubscription.docId
        }&emailAddress=${emailAddress}&promoCode=${promo || "null"}&signUpVersion=7${
          name ? `&signedUpFullName=${name}` : ""
        }&expressCheckout=true&phoneNumber=${phone || ""}`;
      }

      console.log("return url from express payment -- ", returnUrl);

      if (!isNull(tiktokSource)) {
        returnUrl = `${returnUrl}&ttclid=${tiktokSource}`;
      }

      await sendTTQCheckoutEvent(
        emailAddress,
        phone || "",
        selectedSubscription,
        tiktokSource,
        validStripeCoupon
      );

      await startCheckoutKlaviyoEvent(
        selectedSubscription.docId,
        selectedSubscription.price
      );

      await trackWebSignUpStep({
        emailAddress,
        step: "subscription",
        version: 7,
        subscription: selectedSubscription.name,
        promoCode: promo,
        revenue: null,
      });

      // await trackWebSignUpStep(emailAddress, "subscription", 7);

      // Confirm the Intent using the details collected by the Payment Element
      const { error: errorPayment } = await stripe.confirmPayment({
        elements,
        clientSecret: clientSecret,
        confirmParams: {
          return_url: returnUrl,
        },
      });

      if (errorPayment) {
        console.log("got error payment in the end", errorPayment);
        setError(errorPayment.message || "");
      }

      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);
      paymentFailed({ reason: "fail" });
      const { errorMsg, rawErrorMsg } = getErrorMsg(error);
      console.log("error here--", {
        errorMsg,
        error,
      });
      if (
        errorMsg ===
        "User is already existing. please login via winners locker app instead."
      ) {
        setRedirectToApp(true);
      } else {
        setError(errorMsg);
        bugNotif("StepCompleteProfile", rawErrorMsg);
      }
    }
  };

  return (
    <div className={s.paymentContainer}>
      <ExpressCheckoutElement
        onReady={onReady}
        onConfirm={onConfirm}
        onClick={({ resolve }) => {
          const options = {
            emailRequired: true,
            phoneNumberRequired: true,
          };
          console.log("will resolve ", options);
          resolve(options);
        }}
        options={{
          layout: {
            overflow: "never",
          },
        }}
      />

      <IonToast
        isOpen={!isEmpty(error)}
        color="danger"
        message={error}
        duration={5000}
      />
    </div>
  );
};

export default memo(StripeExpressPaymentFormInternal);
