import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import Lottie from "lottie-react";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonImg,
  IonLabel,
  IonPage,
  IonProgressBar,
  IonToast,
} from "@ionic/react";

import s from "./WebPaymentLanding.module.scss";
import brandLogo from "../../assets/images/brand-logo.svg";
import useWebPaymentLandingStore from "./store";
import successAnimation from "../../assets/animations/success-purchase-animation.json";
import { isMobile } from "../../../Common/utils/common";
import { useWebPaymentLandingProvider } from "./provider";
import { WLDownloadButtons } from "./WLDownloadButtons";
import { WLCOLORS } from "../../../Common/config/global";
import { useState } from "react";
import { STRIPE_SUBSCRIPTION_NAME_MAP } from "../../../Common/config";
import { arrowBackOutline, star } from "ionicons/icons";
import useCompleteProfileModalStore from "./CompleteProfileModal/store";
import CompleteProfileModal from "./CompleteProfileModal";
import paymentSuccessImg from "../../assets/images/payment-success-web.png";
import StarsGif from "../../assets/animations/app-rate.gif";
import { ProgressBar } from "./ProgressBar";

export const WebPaymentLanding = ({
  redirectToApp,
  onCloseAsModal,
  isOneOffEntryProp,
}: {
  redirectToApp?: boolean;
  onCloseAsModal?: () => void;
  isOneOffEntryProp?: boolean;
}) => {
  useWebPaymentLandingProvider(redirectToApp);

  const {
    signedUpName,
    pid,
    error,
    paymentMessage,
    paymentError,
    redirectToApp: redirectUserToDownload,
    isExpressCheckout,
    isOneOffEntry,
  } = useWebPaymentLandingStore();

  const { profileIsComplete, isOpen } = useCompleteProfileModalStore();

  const emailAddress = new URLSearchParams(window.location.search).get(
    "emailAddress"
  );
  const phoneNumber = new URLSearchParams(window.location.search).get(
    "phoneNumber"
  );

  const userId = new URLSearchParams(window.location.search).get("userId");

  return (
    <IonPage className={s.wlDownloadApp}>
      <IonContent scrollY>
        {!isMobile() && <IonImg src={brandLogo} className={s.brandLogo} />}
        {isEmpty(paymentError) && !redirectUserToDownload ? (
          <div className={s.downloadAppContentSuccess}>
            <div className={s.mainSuccess}>
              <IonLabel
                className={cx(
                  s.title,
                  "wl-h2-winnersans white ion-text-center"
                )}
              >
                You're In! Welcome to <br /> Winners Locker!
              </IonLabel>

              <div className={s.progressContainer}>
                <ProgressBar />
              </div>

              <IonLabel
                className={cx(
                  s.bonusEntriesLbl,
                  "wl-h6 gilroy white ion-text-center"
                )}
              >
                Score <b>2 bonus entries</b> when you download the Winners
                Locker app and complete your account!{" "}
              </IonLabel>
              <div className={s.paymentSuccessImgContainer}>
                <IonImg src={paymentSuccessImg} />
                <IonImg className={s.starGif} src={StarsGif} />
              </div>
              <IonLabel
                className={cx(
                  s.trackLbl,
                  "wl-body-4 gilroy white ion-text-center"
                )}
              >
                Track your entries, unlock more ways to win, and access all your
                exclusive member perks—right at your fingertips! 🚀
              </IonLabel>

              <WLDownloadButtons />
            </div>
          </div>
        ) : (
          <div className={s.downloadAppContent}>
            <div className={s.main}>
              <IonButton
                fill="clear"
                className={s.backBtn}
                onClick={() => {
                  if (onCloseAsModal !== undefined) {
                    onCloseAsModal();
                  }
                }}
              >
                <IonIcon icon={arrowBackOutline} />
              </IonButton>

              <div
                className={cx(s.downloadContainer, {
                  [s.rediretMode]: !!redirectToApp,
                })}
              >
                <IonLabel className={cx(s.downloadTitle, "wl-large white")}>
                  {paymentMessage || paymentError}
                </IonLabel>
                <IonLabel className="wl-body-6 white">
                  {!isEmpty(paymentMessage) && isEmpty(paymentError)
                    ? "Open the winner locker app and login to access your account"
                    : !isEmpty(paymentError)
                      ? "Please contact customer support."
                      : ""}
                </IonLabel>
                {isEmpty(paymentError) && (
                  <div className={s.subDetails}>
                    {isOneOffEntry || isOneOffEntryProp ? (
                      <>
                        <IonLabel className="wl-body-6 white">
                          <IonIcon icon={star} className={s.star} />
                          Secured one-off entry on the current giveaways
                        </IonLabel>
                        <IonLabel className="wl-body-6 white">
                          <IonIcon icon={star} className={s.star} />
                          Gain access to unlocked more entries
                        </IonLabel>
                        <IonLabel className="wl-body-6 white">
                          <IonIcon icon={star} className={s.star} />
                          Included for the upcoming exclusive promos
                        </IonLabel>
                      </>
                    ) : (
                      <>
                        <IonLabel className="wl-body-6 white">
                          <IonIcon icon={star} className={s.star} /> Unlock
                          bonus entries to major prize giveaways
                        </IonLabel>
                        <IonLabel className="wl-body-6 white">
                          <IonIcon icon={star} className={s.star} /> Gain access
                          to exclusive perks and discounts
                        </IonLabel>
                        <IonLabel className="wl-body-6 white">
                          <IonIcon icon={star} className={s.star} /> Accumulate
                          in-app rewards points
                        </IonLabel>
                        <IonLabel className="wl-body-6 white">
                          <IonIcon icon={star} className={s.star} /> Open your
                          weekly locker for extra prizes
                        </IonLabel>
                      </>
                    )}
                    <IonLabel className="wl-body-6 white">
                      <IonIcon icon={star} className={s.star} /> Plus much more
                      to come
                    </IonLabel>
                  </div>
                )}
                <WLDownloadButtons />
              </div>
            </div>
          </div>
        )}

        {isExpressCheckout && (
          <CompleteProfileModal
            emailAddress={emailAddress || ""}
            phoneNumber={phoneNumber || ""}
            fullName={signedUpName || ""}
            userId={userId || ""}
          />
        )}
      </IonContent>

      <IonToast
        message={error}
        isOpen={!isEmpty(error)}
        duration={2000}
        color={WLCOLORS.danger}
      />
    </IonPage>
  );
};
