export const WEB_SIGN_UP_OLD = "/join-old/:subscriptionKey";
// export const WEB_SIGN_UP_V2 = "/join-v2/:subscriptionKey";
// export const WEB_SIGN_UP_V3 = "/join-v3/:subscriptionKey";
// export const WEB_SIGN_UP_V4 = "/join-v4/:subscriptionKey";
// export const WEB_SIGN_UP_V5 = "/join-v5/:subscriptionKey";
export const WEB_SIGN_UP_V6 = "/join/:subscriptionKey";
export const WEB_SIGN_UP_V6_ONE_OFF = "/enter/:subscriptionKey";
export const WEB_DOWNLOAD_APP = "/download/app";
export const RESET_PASSWORD = "/auth/action";
export const FORGOT_PASSWORD = "/forgot-password";
