import * as capacitorStorage from "../../Common/utils/localStorage";
import { ENVIRONMENTS, LOCAL_STORAGE } from "../../Common/config";
import { createUserSession } from "../services/user";
import { bugNotif } from "../api/bugsnag";
import { getErrorMsg } from "../../Common/utils/common";

export const identifySessionStack = (userId: string) => {
  try {
    if (
      import.meta.env.MODE === ENVIRONMENTS.production &&
      SessionStack !== undefined
    ) {
      SessionStack.getSessionId((sessionId: string) => {
        if (sessionId) {
          capacitorStorage.setItem(LOCAL_STORAGE.sessionId, sessionId);

          createUserSession(userId, sessionId);
        }
      });

      SessionStack.identify({
        userId: userId, // Replace the USER-ID with the user id from your app
      });
    }
  } catch (error) {
    console.log("error on indetifying sessionstack --- ", error);
    bugNotif("identifySessionStack", getErrorMsg(error).rawErrorMsg);
  }
};
