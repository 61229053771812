import cx from "classnames";
import ReactFbq from "react-fbq";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonToast,
  useIonViewDidEnter,
} from "@ionic/react";

import s from "./WebSignUp.module.scss";
import useWebSignupStore from "./store";
import { Suspense, lazy } from "react";
import { auth } from "../../firebase";
import { WLLogoWordMark } from "../../assets/icons";
import { isEmpty, isNull } from "lodash";
import StripeForm from "./StripePaymentForm/StripeForm";
import {
  useSubscriptionsProvider,
  useUpcomingGiveawaysProvider,
  useWebSignupPromoCodeProvider,
  useWebSignupProvider,
} from "./provider";
import stripeTrusted from "../../assets/images/wl-security-badge-stripe.png";
import { WLButton } from "../../../Common/components/WLButton/WLButton";
import { isMobile } from "../../../Common/utils/common";
import { FormProvider } from "react-hook-form";
import { WebPaymentLanding } from "../WebPaymentLanding/WebPaymentLanding";
import { WLCOLORS } from "../../../Common/config/global";

const CompleteProfile = lazy(() => import("./CompleteProfile"));
const SubscriptionSection = lazy(
  () => import("./SubscriptionSection/SubscriptionSection")
);
const PromoCodeSection = lazy(
  () => import("./PromoCodeSection/PromoCodeSection")
);
const StripeSubscriptionOverview = lazy(
  () => import("./StripeSubscriptionOverview/StripeSubscriptionOverview")
);
const CurrentGiveAway = lazy(() => import("./CurrentGiveAway/CurrentGiveAway"));
// const UpcomingGiveaways = lazy(
//   () => import("./UpcomingGiveaways/UpcomingGiveaways")
// );
// const Testaments = lazy(() => import("./Testaments/Testaments"));
const DoublePowerUpEntriesOffer = lazy(
  () => import("./DoublePowerUpEntriesOffer/DoublePowerUpEntriesOffer")
);

// const TrustPoints = lazy(
//   () => import("../../components/TrustPoints/TrustPoints")
// );

(ReactFbq as any).initialize({ id: "3689540701275716" });
(ReactFbq as any).pageView();
export const WebSignUp = () => {
  const { selectedSubscription } = useWebSignupStore();

  const { useFormMethods, error, redirectToApp, setRedirectToApp, onSubmit } =
    useWebSignupProvider();
  useWebSignupPromoCodeProvider();
  useUpcomingGiveawaysProvider();
  useSubscriptionsProvider();

  useIonViewDidEnter(() => {
    auth.signOut();
  });

  return (
    <IonPage className={s.page}>
      <IonContent className={s.content} fullscreen>
        {/* {isMobile(true) && (
          <Suspense>
            <div className={s.trustpointsMobileContainer}>
              <TrustPoints />
            </div>
          </Suspense>
        )} */}
        <div className={s.body}>
          <div className={s.innerContainer}>
            <WLLogoWordMark className={s.logo} />

            <FormProvider {...useFormMethods}>
              <form className={s.form} onSubmit={onSubmit}>
                <IonGrid className={s.mainDetails}>
                  <IonRow>
                    <IonCol
                      size={!isMobile(true) ? "5.79" : "12"}
                      className={cx(s.leftDetails, "ion-no-padding")}
                    >
                      <div className={s.header}>
                        <IonLabel
                          className={cx(
                            s.headerTitle,
                            "wl-h2-winnersans primary"
                          )}
                        >
                          ENTER DETAILS TO WIN
                        </IonLabel>
                        <IonLabel
                          className={cx(
                            s.headerDesc,
                            "wl-body-2-gilroy gilroy-regular light-dark"
                          )}
                        >
                          Fill in your details so we know how to
                          {isMobile(true) && <br />} contact you if you win.
                        </IonLabel>
                      </div>

                      {/* <div className={s.expressContainer}>
                        <StripeExpressPaymentForm />
                      </div> */}

                      <Suspense>
                        <CompleteProfile useFormMethods={useFormMethods} />
                      </Suspense>

                      {!isMobile(true) ? (
                        <>
                          <StripeForm />
                          {/* <StripeExpressPaymentForm /> */}

                          <WLButton
                            type="submit"
                            disabled={
                              selectedSubscription === undefined ||
                              isNull(selectedSubscription)
                            }
                            className={cx(
                              s.submitBtn,
                              "wl-header-gilroy red-submit-button"
                            )}
                            id="wl-sign-up-v3"
                            isFormComponent
                          >
                            ENTER NOW
                          </WLButton>

                          <div className={s.footer}>
                            <div>
                              <IonImg
                                className={s.stripeTrusted}
                                src={stripeTrusted}
                              />
                            </div>

                            <IonLabel
                              className={cx(
                                s.servicePolicy,
                                "wl-body-5 gilroy-regular light-dark ion-text-center"
                              )}
                            >
                              By continuing, you agree to Winners Locker{" "}
                              <a
                                className={s.link}
                                onClick={() => {
                                  window.open(
                                    "https://winnerslocker.com.au/terms-and-conditions"
                                  );
                                }}
                              >
                                Terms of Service
                              </a>{" "}
                              and the{" "}
                              <a
                                className={s.link}
                                onClick={() => {
                                  window.open(
                                    "https://winnerslocker.com.au/privacy-policy"
                                  );
                                }}
                              >
                                Privacy Policy
                              </a>
                              .
                            </IonLabel>
                          </div>
                        </>
                      ) : (
                        <>
                          <Suspense>
                            <CurrentGiveAway />
                          </Suspense>

                          <Suspense>
                            <StripeSubscriptionOverview />
                          </Suspense>

                          {/* <Suspense>
                            <UpcomingGiveaways />
                          </Suspense> */}

                          {/* {!isMobile(true) && (
                            <Suspense>
                              <TrustPoints />
                            </Suspense>
                          )} */}

                          <Suspense>
                            <DoublePowerUpEntriesOffer />
                          </Suspense>

                          {/* <Suspense>
                            <Testaments />
                          </Suspense> */}
                        </>
                      )}
                    </IonCol>

                    {!isMobile(true) && (
                      <IonCol size="0.42" className="ion-no-padding"></IonCol>
                    )}

                    <IonCol
                      size={!isMobile(true) ? "5.79" : "12"}
                      className={cx(s.rightDetails, "ion-no-padding")}
                    >
                      {!isMobile(true) ? (
                        <>
                          <Suspense>
                            <SubscriptionSection />
                          </Suspense>

                          <Suspense>
                            <PromoCodeSection />
                          </Suspense>

                          <Suspense>
                            <CurrentGiveAway />
                          </Suspense>

                          <Suspense>
                            <StripeSubscriptionOverview />
                          </Suspense>

                          {/* <Suspense>
                            <UpcomingGiveaways />
                          </Suspense> */}

                          <Suspense>
                            <DoublePowerUpEntriesOffer />
                          </Suspense>

                          {/* <Suspense>
                            <Testaments />
                          </Suspense> */}
                        </>
                      ) : (
                        <>
                          <StripeForm />

                          <Suspense>
                            <SubscriptionSection />
                          </Suspense>

                          <Suspense>
                            <PromoCodeSection />
                          </Suspense>

                          <WLButton
                            type="submit"
                            disabled={
                              selectedSubscription === undefined ||
                              isNull(selectedSubscription)
                            }
                            className={cx(
                              s.submitBtn,
                              "wl-body-6 red-submit-button"
                            )}
                            id="wl-sign-up-v3"
                            isFormComponent
                          >
                            ENTER NOW
                          </WLButton>

                          <div className={s.footer}>
                            <div>
                              <IonImg
                                className={s.stripeTrusted}
                                src={stripeTrusted}
                              />
                            </div>

                            <IonLabel
                              className={cx(
                                s.servicePolicy,
                                "wl-body-5 gilroy-regular light-dark ion-text-center"
                              )}
                            >
                              By continuing, you agree to Winners Locker{" "}
                              <a
                                className={s.link}
                                onClick={() => {
                                  window.open(
                                    "https://winnerslocker.com.au/terms-and-conditions"
                                  );
                                }}
                              >
                                Terms of Service
                              </a>{" "}
                              and the{" "}
                              <a
                                className={s.link}
                                onClick={() => {
                                  window.open(
                                    "https://winnerslocker.com.au/privacy-policy"
                                  );
                                }}
                              >
                                Privacy Policy
                              </a>
                              .
                            </IonLabel>
                          </div>
                        </>
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </form>
            </FormProvider>
          </div>

          <IonModal isOpen={redirectToApp} className={s.redirectToAppModal}>
            <WebPaymentLanding
              redirectToApp
              onCloseAsModal={() => {
                setRedirectToApp(false);
              }}
              isOneOffEntryProp
            />
          </IonModal>

          <IonToast
            message={error}
            isOpen={!isEmpty(error)}
            duration={2000}
            color={WLCOLORS.danger}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};
