import { LockerRewardType } from "./locker";
import { PowerUpTypes } from "./subscriptions";

export interface MobileVersionRelease {
  docId: string;
  version: string;
  nativeUpdateRequired: boolean;
  android_native_required?: boolean;
  ios_native_required?: boolean;
  isApproved?: boolean;
  createdDt: number;
  updatedDt?: number;
  updatedBy: string;
}

export type LevelPrizeType = "entries" | "merch";
export interface Merchandise {
  name: string;
  //! this is temp
}
export interface LevelRewardsMatrix {
  docId: string;
  level: number;
  name: string;
  pointsToAchieve: number;
  prizeType: LevelPrizeType;
  prize: number | Merchandise;
  createdDt: number;
  updatedDt: number;
}

export type LevelRewardsMatrixCreate = Omit<
  LevelRewardsMatrix,
  "docId" | "createdDt" | "updatedDt"
>;

type MomentFrequency = "day" | "date" | "minute";
export interface LockerConfig {
  momentFrequency: MomentFrequency; // day | week moment(new Date()).get("day")
  momentFrequencyEvery: number;
}

export type ReferralBonusType = 1 | 2 | 3;

export const ReferralBonusTypeSourceMap = {
  1: "Bonus Power Up",
  2: "Bonus Entries",
  3: "Entries Multiplier", //! will only apply to sign ups (referred user)
};

export type ReferralSourceType = {
  [key: string]: {
    id: ReferralBonusType;
    name: string;
  };
};

export const REFERRAL_SOURCE_CONFIG: ReferralSourceType = {
  bonusPowerUps: { id: 1, name: "Bonus Power Ups" },
  bonusEntries: { id: 2, name: "Bonus Entries" },
  entriesMultiplier: { id: 3, name: "Entries Multiplier" },
} satisfies ReferralSourceType;

export interface ReferralPowerUpRewardsType {
  [key: number]: {
    title: string;
    id: PowerUpTypes;
    value: number;
    entries: number;
  };
}

export const ReferralPowerUpTypeSourceMap: ReferralPowerUpRewardsType = {
  1: {
    id: 1,
    title: "$25 Power Up",
    value: 25,
    entries: 2,
  },
  2: {
    id: 2,
    title: "$50 Power Up",
    value: 50,
    entries: 5,
  },
  3: {
    id: 3,
    title: "$100 Power Up",
    value: 100,
    entries: 20,
  },
  4: {
    id: 4,
    title: "$250 Power Up",
    value: 250,
    entries: 60,
  },
  5: {
    id: 5,
    title: "$500 Power Up",
    value: 500,
    entries: 150,
  },
} satisfies ReferralPowerUpRewardsType;

export type ReferralBonusValueType = PowerUpTypes | number;

export interface ReferralBonus {
  type: ReferralBonusType;
  value: ReferralBonusValueType;
}

export interface ReferralConfig {
  twoWayBonus: ReferralBonus;
  referrerBonus: ReferralBonus;
  referredBonus: ReferralBonus;

  isDefault: boolean;
  lookUpKey: string;
}

export interface WLConfig {
  docId: string;
  locker: LockerConfig;
  showPowerUpDoubleFor50OffOffer: boolean;
  hardCodedRevenue: {
    updatedDt: number;
    revenue: number;
  };
  displayUpsell: boolean;
  respinCost: number;
  cancelFlowDiscount: number;
  firstUpgradeOfferCode: string;
  secondUpgradeOfferCode: string;
  thirdUpgradeOfferStarterCode: string;
  thirdUpgradeOfferPROCode: string;
  thirdUpgradeOfferMVPCode: string;

  oneTimePowerUpOffer: string;
  defaultPowerUpOffer: string;
  fourthFailedPaymentOffer: string;
  sixthFailedPaymentOffer: string;
  lastFailedPaymentOfferStarter: string;
  lastFailedPaymentOfferPro: string;
  lastFailedPaymentOfferMVP: string;
  lastFailedPaymentOfferLegend: string;
  lastFailedPaymentOfferVIP: string;
  reactivateAccountOffer: string;
  reactivateOfferExpirationDt: number;
  // powerUpOfferOnCancel: string;
  createdDt: number;
  updatedDt: number;
  updatedBy: string;
  updatedByEmail: string;
  referralConfig: ReferralConfig[];
  lockerRewardsList: RewardListConfig[];
}

export type WLConfigCreate = Omit<
  WLConfig,
  "docId" | "createdDt" | "updatedDt" | "hardCodedRevenue" | "displayUpsell"
>;

export interface WLWhiteList {
  userEmails: string[];
}

export interface UserErrors {
  docId: string;
  userId: string;
  build: string;
  device: string;
  platform: string;
  url: string;
  error: string;
  firstEncounteredDt: number;
  createdDt: number;
}

export interface CreateUserErrors
  extends Omit<UserErrors, "docId" | "createdDt"> {}

export interface WLCreateMetaData {
  createdDt: number;
  createdBy: string;
}

export interface WLUpdateMetaData {
  updatedDt: number;
  updatedBy: string;
}

export type WLMetaData = WLCreateMetaData & WLUpdateMetaData;

export type WLOmittedMetaData =
  | "docId"
  | "createdDt"
  | "updatedDt"
  | "createdBy"
  | "updatedBy";

export interface RewardListConfig {
  id: string;
  type: LockerRewardType;
  amount: number;
  chance: number;
  powerUpType?: PowerUpTypes;
}

export type RewardListConfigCreate = Omit<RewardListConfig, "docId">;
