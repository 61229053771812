import { create } from "zustand";

interface CompleteProfileState {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;

  profileIsComplete: boolean;
  setIsProfileComplete: (state: boolean) => void;
}

const useCompleteProfileModalStore = create<CompleteProfileState>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set({ isOpen }),

  profileIsComplete: false,
  setIsProfileComplete: (profileIsComplete) => set({ profileIsComplete }),
}));

export default useCompleteProfileModalStore;
