import * as React from "react";
import * as _ from "lodash";
import {
  IonList,
  IonListHeader,
  IonSearchbar,
  IonItemDivider,
  IonLabel,
  IonButton,
  IonThumbnail,
} from "@ionic/react";

import "./WLCountryCodes.scss";
import countriesJson from "../../../../constants/countries.json";
import { WLDivider } from "../../../WLDivider/WLDivider";
import { useState } from "react";
import { ENVIRONMENTS } from "../../../../config";

export interface Country {
  code: string;
  flag: string;
  callingCode: string;
  name: string;
}
interface WLCountryCodesProps {
  onChange: (selectedCountry: Country) => void;
  onCancel: () => void;
}

interface CountryOption {
  code: string;
  isDivider: boolean;
  flag: any;
  callingCode: string;
  name: string;
}

export const getAllCountries = () =>
  _.filter(
    Object.keys(countriesJson).map((country) => {
      // console.log("getting them countries");
      const countryItem = (countriesJson as any)[country];
      return {
        code: country,
        isDivider: countryItem.isDivider,
        flag: countryItem.flag,
        callingCode: countryItem.callingCode,
        name: countryItem.name.common,
      };
    }),
    (countryItem: any) => {
      return (
        [
          "AU",
          "NZ",
          ...([
            ENVIRONMENTS.local,
            ENVIRONMENTS.development,
            ENVIRONMENTS.staging,
          ].indexOf(import.meta.env.MODE) !== -1
            ? ["PH", "US"]
            : []),
        ].indexOf(countryItem.code) !== -1
      );
    }
  );
export const WLCountryCodes = (props: WLCountryCodesProps) => {
  const { onChange, onCancel } = props;

  const [countrySearch, setCountrySearch] = useState("");
  const [countriesOptions] = useState(getAllCountries());
  const [filteredCountriesOptions, setFilteredCountriesOptions] = useState(
    null as CountryOption[] | null
  );

  const searchCountry = (query: string) => {
    return _.filter(
      countriesOptions,
      (country) =>
        country.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        (!_.isEmpty(country.callingCode) &&
          country.callingCode.indexOf(query) !== -1)
    );
  };

  return (
    <>
      <IonList className="ca-countries-options-container">
        <IonListHeader className="ca-countries-modal-header">
          <div className="ca-search-bar-container">
            <IonSearchbar
              showCancelButton="always"
              onIonCancel={onCancel}
              value={countrySearch}
              onIonInput={(event: CustomEvent) => {
                const value = event.detail.value || "";
                setCountrySearch(value);
                if (!_.isEmpty(value)) {
                  setFilteredCountriesOptions(searchCountry(value));
                } else {
                  setFilteredCountriesOptions(null);
                }
              }}
              placeholder="Search for a country"
              className="ca-countries-search-input"
            />
          </div>
        </IonListHeader>
        {(filteredCountriesOptions || countriesOptions).map((country) => {
          if (country.isDivider) {
            return (
              <IonItemDivider
                className="ca-countries-divider"
                sticky={true}
                key={country.name}
              >
                <IonLabel>{country.name}</IonLabel>
              </IonItemDivider>
            );
          } else {
            return (
              <div className="ca-country-item" key={country.code}>
                <IonButton
                  expand="full"
                  fill="clear"
                  className="ca-country-item-button"
                  onClick={() => {
                    onChange(country);
                  }}
                >
                  <div className="ca-country-item-button-contents">
                    <IonThumbnail className="ca-flag-thumbnail-modal">
                      <img
                        className="ca-flag-image"
                        src={country.flag}
                        alt="country-flag"
                      />
                    </IonThumbnail>
                    <IonLabel className="ca-h4 dark">
                      {country.name} ({country.callingCode})
                    </IonLabel>
                  </div>
                </IonButton>
                <WLDivider />
              </div>
            );
          }
        })}
      </IonList>
    </>
  );
};
