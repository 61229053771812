import cx from "classnames";
import compact from "lodash/compact";
import { memo, useMemo, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import {
  loadStripe,
  StripeExpressCheckoutElementReadyEvent,
} from "@stripe/stripe-js";

import s from "./StripeExpressPaymentForm.module.scss";
import { stripeKey } from "../../../firebase";
import StripeExpressPaymentFormInternal from "./StripeExpressPaymentFormInternal";
import { IonLabel } from "@ionic/react";
import { WLSkeleton } from "../../../../Common/components/WLSkeleton/WLSkeleton";
import useWebSignupStore from "../store";
import { isNull } from "lodash";
import { getStripePriceValueWithPromo } from "../../../services/promo";
import { WLDivider } from "../../../../Common/components/WLDivider/WLDivider";
import { isMobile } from "../../../../Common/utils/common";

const stripePromise = loadStripe(stripeKey);

const StripeExpressPaymentForm = ({
  setRedirectToApp,
}: {
  setRedirectToApp: (data: boolean) => void;
}) => {
  const { selectedSubscription, validStripeCoupon } = useWebSignupStore();

  const [visibility, setVisibility] = useState(
    "hidden" as "hidden" | "initial"
  );

  const amount = useMemo(() => {
    if (
      selectedSubscription === undefined ||
      validStripeCoupon === undefined ||
      isNull(selectedSubscription)
    ) {
      return;
    }

    return !isNull(validStripeCoupon)
      ? Math.round(
          getStripePriceValueWithPromo(
            selectedSubscription.price,
            validStripeCoupon
          ) * 100
        ) // Convert to the smallest currency unit
      : Math.round(selectedSubscription.price * 100);
  }, [selectedSubscription, validStripeCoupon]);

  const onReady = ({
    availablePaymentMethods,
  }: StripeExpressCheckoutElementReadyEvent) => {
    if (
      !availablePaymentMethods ||
      !compact(Object.values(availablePaymentMethods))
    ) {
      // No buttons will show
    } else {
      // Optional: Animate in the Element
      setVisibility("initial");
    }
  };

  return (
    <div
      className={s.container}
      id="wl-express-checkout-container"
      style={{
        height: visibility !== "hidden" ? "unset" : "0px",
        minHeight: isMobile() ? "123px" : "135px",
      }}
    >
      <IonLabel className={cx(s.title, "wl-medium primary normal")}>
        Express Checkout
      </IonLabel>
      {amount !== undefined ? (
        <div
          className={s.elementsContainer}
          style={{
            visibility,
          }}
        >
          <Elements
            stripe={stripePromise}
            options={{
              locale: "en-AU",
              amount,
              currency: "aud",
              setup_future_usage: "off_session",
              mode: "subscription",
              payment_method_types: ["card", "link"],
            }}
          >
            <StripeExpressPaymentFormInternal
              onReady={onReady}
              setRedirectToApp={setRedirectToApp}
            />
          </Elements>

          <WLDivider text="or" hasPadding />
        </div>
      ) : (
        <WLSkeleton width="100%" height="100%" />
      )}
    </div>
  );
};

export default memo(StripeExpressPaymentForm);
