import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import Stripe from "stripe";

import { Promo, PromoTypeConfig } from "../../Common/models/promos";
import { WLSubscriptionsTiers } from "../../Common/models/subscriptions";
import { getErrorMsg } from "../../Common/utils/common";
import { bugNotif } from "../api/bugsnag";
import { validatePromoUnauthenticated } from "../api/promo";

interface iPromoValidParams extends PromoTypeConfig {
  code: string;
  userId?: string;
}

export const isPromoValid = async (data: iPromoValidParams) => {
  try {
    const result = await validatePromoUnauthenticated(data);
    return result.data.result;
  } catch (error) {
    const { rawErrorMsg: errorMsg } = getErrorMsg(error);
    bugNotif("isPromoValid", errorMsg);
    return null;
  }
};

export const getPromoLabel = (
  promo: Promo,
  isOneOff = false,
  existingMember = false
) => {
  const { entryPoints, rewardPoints, isMultiplier } = promo;

  let label = "Get";
  const hasEntryAndPoints = entryPoints > 0 && rewardPoints > 0;
  if (isMultiplier) {
    if (entryPoints > 0) {
      label = `${label} ${entryPoints + 1}X entries`;
    }
    if (rewardPoints > 0 && !isOneOff) {
      label = `${label}${hasEntryAndPoints ? ", and " : " "}${
        rewardPoints + 1
      }X points`;
    }
  } else {
    if (entryPoints > 0) {
      label = `${label} ${entryPoints} extra entries`;
    }
    if (rewardPoints > 0 && !isOneOff) {
      label = `${label}${
        hasEntryAndPoints ? ", and " : " "
      }${rewardPoints} extra points`;
    }
  }

  return `${label}${
    !isOneOff && !existingMember
      ? promo.bonusMonths !== undefined && promo.bonusMonths > 1
        ? ` for ${promo.bonusMonths} months`
        : " this month"
      : ""
  }`;
};

// used in v2 web sign up
export const getCouponDiscountAmount = (
  coupon: Stripe.Coupon,
  oneOff = false,
  amount: number
) => {
  const { amount_off, percent_off, duration } = coupon;

  let discountAmount = !isNull(amount_off)
    ? amount_off / 100
    : !isNull(percent_off)
      ? amount * parseFloat(`0.${percent_off}`)
      : 0;

  discountAmount =
    parseInt(discountAmount.toFixed()) < parseFloat(discountAmount.toFixed(1))
      ? parseFloat(discountAmount.toFixed(1))
      : parseInt(discountAmount.toFixed());

  if (oneOff || duration === "forever" || duration === "repeating") {
    return discountAmount;
  }

  return amount - discountAmount;
};

export const getCouponDiscountDescription = (
  coupon: Stripe.Coupon,
  oneOff = false
) => {
  const { duration } = coupon;
  let discountDescription = "";
  if (oneOff || duration === "forever" || duration === "repeating") {
    discountDescription = "OFF";
  } else {
    discountDescription = "Discount for the first month";
  }
  return discountDescription;
};

export const getStripePromoAmount = (coupon: Stripe.Coupon, amount: number) => {
  const { amount_off, percent_off } = coupon;

  let discountAmount = !isNull(amount_off)
    ? amount_off / 100
    : !isNull(percent_off)
      ? amount * parseFloat(`0.${percent_off}`)
      : 0;

  discountAmount =
    discountAmount.toFixed(2).split(".")[1] === "00"
      ? parseInt(discountAmount.toFixed())
      : parseFloat(discountAmount.toFixed(2));

  return discountAmount;
};

// TODO use getCouponDiscountAmount above
// to remove redundant logic
export const getStripePromoLabel = (
  coupon: Stripe.Coupon,
  oneOff = false,
  amount: number
) => {
  const { duration } = coupon;
  const discountAmount = getStripePromoAmount(coupon, amount);

  if (oneOff || duration === "forever" || duration === "repeating") {
    return `Save $${discountAmount}`;
  }

  return `Save $${discountAmount} for the first month`;
};

export const getStripeAndWLPromoLabel = (
  stripeCoupon: Stripe.Coupon | null,
  wlPromo: Promo | null
) => {
  if (isNull(stripeCoupon) && isNull(wlPromo)) {
    return "";
  }
  //If both once
  const stripeLabel = !isNull(stripeCoupon) ? "Discount" : "";
  const promoLabel = !isNull(wlPromo) ? "Promo" : "";
  const stripePromoLabel = compact([stripeLabel, promoLabel]).join(" and ");

  return !isEmpty(stripePromoLabel)
    ? `${stripePromoLabel} for the first month`
    : "";
};

export const getWLPromoDescription = (
  wlPromo: Promo,
  subscription: WLSubscriptionsTiers,
  isPowerUp = false
) => {
  const entries = wlPromo.isMultiplier
    ? wlPromo.entryPoints * subscription.entriesPerMonth
    : wlPromo.entryPoints;

  const entriesLabel =
    entries > 0
      ? `${entries} bonus ${isPowerUp ? "power up " : ""}entries`
      : "";

  const rewards = wlPromo.isMultiplier
    ? wlPromo.rewardPoints * subscription.rewardPointsPerMonth
    : wlPromo.rewardPoints;

  const rewardsLabel = rewards > 0 ? `${rewards} bonus points` : "";

  return `get ${compact([entriesLabel, rewardsLabel]).join(" and ")}`;
};

// export const getWLPromoDescriptionNative = (
//   wlPromo: Promo,
//   subscription: WLSubscriptionsTiers
// ) => {
//   const entries = wlPromo.isMultiplier
//     ? wlPromo.entryPoints * subscription.entriesPerMonth
//     : wlPromo.entryPoints;

//   const entriesLabel = entries > 0 ? `${entries} bonus entries` : "";

//   const rewards = wlPromo.isMultiplier
//     ? wlPromo.rewardPoints * subscription.rewardPointsPerMonth
//     : wlPromo.rewardPoints;

//   const rewardsLabel = rewards > 0 ? `${rewards} bonus points` : "";

//   return `+ ${compact([entriesLabel, rewardsLabel]).join(" and ")} this month`;
// };

export const getWLPromoEntries = (
  wlPromo: Promo,
  subscription: WLSubscriptionsTiers
) => {
  const entries = wlPromo.isMultiplier
    ? wlPromo.entryPoints * subscription.entriesPerMonth
    : wlPromo.entryPoints;

  return entries;
};

export const getStripePriceWithPromo = (
  price: number,
  coupon: Stripe.Coupon
) => {
  const { amount_off, percent_off } = coupon;

  let total = 0;
  if (!isNull(amount_off)) {
    total = price - amount_off / 100;
  } else if (!isNull(percent_off)) {
    total = price * ((100 - percent_off) / 100);
  }
  return `$${parseFloat(total.toFixed(2))}`;
};

export const getStripePriceValueWithPromo = (
  price: number,
  coupon: Stripe.Coupon
) => {
  const { amount_off, percent_off } = coupon;

  let total = 0;
  if (!isNull(amount_off)) {
    total = price - amount_off / 100;
  } else if (!isNull(percent_off)) {
    total = price * ((100 - percent_off) / 100);
  }
  return total;
};

export const getEntriesWithPromo = (entries: number, promo: Promo) => {
  let entriesToReturn = entries;

  const { entryPoints, isMultiplier } = promo;

  if (isMultiplier) {
    entriesToReturn += entriesToReturn * entryPoints;
  } else {
    entriesToReturn += entryPoints;
  }

  return entriesToReturn;
};

export const getExtraEntriesWithPromo = (entries: number, promo: Promo) => {
  let entriesToReturn = entries;

  const { entryPoints, isMultiplier } = promo;

  if (isMultiplier) {
    entriesToReturn = entriesToReturn * entryPoints;
  } else {
    entriesToReturn = entryPoints;
  }

  return entriesToReturn;
};
