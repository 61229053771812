import sortBy from "lodash/sortBy";
import filter from "lodash/filter";

import { db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  limit,
} from "../../Common/utils/firebaseFunctions";
import { Giveaway, GiveawayType } from "../../Common/models/giveaways";
import { GIVEAWAYS } from "../constants/dbCollections";
import moment from "moment";

export const getOpenGiveaway = async (date = new Date().valueOf()) => {
  let giveawayQuery = await getDocs(
    query(
      collection(db, GIVEAWAYS),
      where("type", "in", ["super", "cash"] as GiveawayType[])
    )
  );

  return sortBy(
    filter(
      giveawayQuery.docs.map(
        (doc) => ({ ...doc.data(), docId: doc.id }) as Giveaway
      ),
      (giveaway) =>
        giveaway.giveAwayDt >= date &&
        giveaway.openDt !== undefined &&
        giveaway.openDt <= date
    ),
    "giveAwayDt"
  );
};

export const getGiveawayViaCode = async (code: string) => {
  let giveawayQuery = await getDocs(
    query(collection(db, GIVEAWAYS), where("code", "==", code), limit(1))
  );

  if (giveawayQuery.empty) {
    return null;
  }
  const doc = giveawayQuery.docs[0];
  return {
    ...doc.data(),
    docId: doc.id,
  } as Giveaway;
};

export const getUpcomingGiveaways = async () => {
  let giveawayQuery = await getDocs(
    query(collection(db, GIVEAWAYS), where("type", "in", ["super"]))
  );

  const toOpen: Giveaway[] = [];
  const toDraw: Giveaway[] = [];

  giveawayQuery.docs.forEach((giveaway) => {
    const giveawayData = { docId: giveaway.id, ...giveaway.data() } as Giveaway;
    if (
      giveawayData.openDt === undefined ||
      (giveawayData.openDt !== undefined &&
        giveawayData.openDt < new Date().valueOf())
    ) {
      // to draw
      toDraw.push(giveawayData);
    } else {
      toOpen.push(giveawayData);
    }
  });

  const toDrawResult = filter(
    sortBy(toDraw, "giveAwayDt"),
    (item) =>
      item.giveAwayDt >=
      moment(new Date().valueOf()).startOf("day").toDate().valueOf()
  );
  const toOpenResult = filter(
    sortBy(toOpen, "openDt"),
    (item) =>
      item.giveAwayDt >=
      moment(new Date().valueOf()).startOf("day").toDate().valueOf()
  );

  const result = [...toDrawResult, ...toOpenResult];

  return filter(result, (res) => !res.closed);
};
